import { useContext, useMemo, useState } from "react";
import { AuctionLogic } from ".";
import GameStateContext, {
  GameState,
  PassAuctionPlayer,
} from "../../GameStateContext";
import { Player } from "../../data/Player";
import { startDeck } from "../../data/cardData";
import { buyPainting, findHighestBidder } from "../GameActions";
import {
  serializeCards,
  serializeMoney,
  serializePlayer,
} from "../LogSerialization";

function afterBid(
  gameState: GameState,
  newPlayerBids: Record<string, number>,
  auctionPlayerIndex: number | null,
  setAuctionPlayerIndex: React.Dispatch<number | null>
): boolean {
  // If everyone had a chance to bid, end the auction
  if (Object.keys(newPlayerBids).length === gameState.gamePlayers.length)
    return true;

  // If the auction isn't over, pass the "auction turn" to the next player
  PassAuctionPlayer(
    gameState.gamePlayers,
    auctionPlayerIndex,
    setAuctionPlayerIndex
  );

  return false;
}

function endAuction(
  gameState: GameState,
  newPlayerBids: Record<string, number>
) {
  const highest = findHighestBidder(newPlayerBids);
  let highestBidder;
  let highestBid;

  if (!highest?.bid) {
    // No one bid above 0, so active player buys the auctionSelectedCard for 0
    highestBidder = gameState.activePlayer;
    highestBid = 0;
  } else {
    highestBidder = gameState.gamePlayers.find(
      (p) => p.name === highest.playerName
    );
    highestBid = highest.bid;
  }

  if (highestBidder) {
    const auctionSelectedCards = gameState.auctionSelectedCardIds.map(
      (id) => startDeck[id]
    );

    buyPainting(
      gameState.activePlayer,
      highestBidder,
      auctionSelectedCards,
      highestBid
    );

    gameState.addLog(
      serializePlayer(highestBidder, gameState.gamePlayers),
      "wins",
      serializeCards(auctionSelectedCards),
      "for",
      serializeMoney(highestBid)
    );
  }
}

interface RenderAuctionDialogContentProps {
  player: Player;
  playerIndex: number;
}

const RenderAuctionDialogContent: React.FC<RenderAuctionDialogContentProps> = ({
  player,
  playerIndex,
}) => {
  const gameState = useContext(GameStateContext);

  const [bidAmount, setBidAmount] = useState("");

  const isSubmitted = useMemo(
    () => player.name in gameState.playerBids,
    [player.name, gameState.playerBids]
  );

  const currentHighestBid = Object.values(gameState.playerBids).reduce(
    (maxBid, bidAmount) => Math.max(maxBid, bidAmount),
    0
  );

  const auctionPlayer =
    gameState.auctionPlayerIndex != null
      ? gameState.gamePlayers[gameState.auctionPlayerIndex]
      : null;

  const handlePass = () => {
    gameState.submitPlayerBid(player, 0);
  };

  const handleBidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBidAmount = parseInt(event.target.value) || "";
    setBidAmount(newBidAmount.toString());
  };

  const handleBidSubmit = () => {
    gameState.submitPlayerBid(player, parseInt(bidAmount));
    setBidAmount("");
  };

  const isValidBid =
    bidAmount !== "" &&
    parseInt(bidAmount) >= 0 &&
    parseInt(bidAmount) <= player.money &&
    !isSubmitted &&
    parseInt(bidAmount) > currentHighestBid;

  return (
    <div>
      <b>ONE OFFER AUCTION:</b>
      <div>
        {Object.entries(gameState.playerBids).length > 0 && (
          <>
            {Object.entries(gameState.playerBids).length === 0 ? (
              <span>&nbsp;</span>
            ) : (
              Object.entries(gameState.playerBids)
                .map(
                  ([playerName, bidAmount]) => `${playerName}: $${bidAmount}`
                )
                .join(", ")
            )}
          </>
        )}
        {!gameState.playerBids.length && <>&nbsp;</>}
      </div>
      <div>
        One bid per player, the auctioneer bids last.
        <br />
        Current bidder: {auctionPlayer?.name}
        {gameState.auctionPlayerIndex === playerIndex && (
          <div>
            <label>
              Bid Amount:{" "}
              <input
                type="number"
                value={bidAmount}
                onChange={handleBidChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && isValidBid) {
                    handleBidSubmit();
                  }
                }}
                style={{ width: "40px" }}
              />
            </label>
            <button onClick={handleBidSubmit} disabled={!isValidBid}>
              Submit Bid
            </button>
            <button onClick={handlePass}>Pass</button>
          </div>
        )}
      </div>
    </div>
  );
};

const logic: AuctionLogic = {
  afterBid,
  endAuction,
  RenderAuctionDialogContent,
};

export default logic;
