import { useContext, useState } from "react";
import { Artists, Card } from "../data/cardData";
import { auctionIcons } from "./AuctionIcons";
import GameStateContext from "../GameStateContext";
import CardTooltip from "./CardTooltip";

export default function CardImage({
  card,
  style,
}: {
  card: Card;
  style?: any;
}) {
  const { selectedCard, auctionSelectedCardIds } = useContext(GameStateContext);
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = selectedCard?.id === card.id;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const tooltipVisible = auctionSelectedCardIds?.length
    ? isHovered
    : (isHovered && selectedCard === null) || isSelected;

  return (
    <>
      {tooltipVisible && (
        <CardTooltip card={isSelected ? selectedCard : card} />
      )}
      <div
        className={`card-wrapper ${isSelected ? "selected" : ""}`}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="card-header"
          style={{
            backgroundColor: Artists[card.artwork.artistId].colorHex,
          }}
        >
          <div className="card-artist">
            {Artists[card.artwork.artistId].name}
          </div>
          <div className="card-artwork-name">{card.artwork.name}</div>
          <div className="card-icon">{auctionIcons[card.auctionType]}</div>
        </div>
        <img
          className="card-image"
          src={`./artwork/${card.artwork.imgUrl}`}
          alt={card.artwork.name}
        />
      </div>
    </>
  );
}
