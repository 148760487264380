import { useContext } from "react";
import GameStateContext from "../GameStateContext";
import auctionLogics from "../logic/auctions";

const PlayerAuctionDialog = ({ playerIndex }: { playerIndex: number }) => {
  const {
    auctionInProgressType,
    gamePlayers,
    activePlayerIndex,
    rounds,
    isGameOver,
  } = useContext(GameStateContext);
  const player = gamePlayers[playerIndex];
  const shouldDisplayTurnReminder =
    player === gamePlayers[activePlayerIndex] &&
    rounds.length > 0 &&
    !isGameOver;

  // early return to catch non-defined case
  if (!auctionInProgressType)
    return (
      <>
        {shouldDisplayTurnReminder && (
          <div>
            It is your turn! Select a card to put up for auction, then press
            'submit'.
          </div>
        )}
      </>
    );

  const ContentComponent =
    auctionLogics[auctionInProgressType].RenderAuctionDialogContent;

  return (
    <div>
      <ContentComponent player={player} playerIndex={playerIndex} />
    </div>
  );
};

export default PlayerAuctionDialog;
