import { Player } from "../data/Player";
import { Artists, Card, Color, Deck, colorPriority } from "../data/cardData";

export function initPlayer(name: string, position: number): Player {
  return {
    name,
    position,
    money: 100,
    hand: [],
    paintingsBought: [],
  };
}

export function addPlayer(players: Player[], playerName: string): Player[] {
  const newPlayer = initPlayer(playerName, players.length);
  return [...players, newPlayer];
}

export function determineStartingPlayer(players: Player[]): Player {
  const startingPlayer = players[Math.floor(Math.random() * players.length)];

  return startingPlayer;
}

export function shuffleCards(deck: Deck) {
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
}

export function incrementPlayerIndex(
  playerCount: number,
  currentIndex: number,
  increment = 1
) {
  const nextIndex = currentIndex + increment;
  if (nextIndex >= playerCount) {
    return nextIndex % playerCount;
  }
  if (nextIndex < 0) {
    return playerCount + (nextIndex % playerCount);
  }

  return nextIndex;
}

const sortBidsDescending = (playerBids: Record<Player["name"], number>) => {
  return Object.keys(playerBids)
    .map((k) => ({ playerName: k, bid: playerBids[k] }))
    .sort((a, b) => b.bid - a.bid);
};

export const findHighestBidder = (
  playerBids: Record<Player["name"], number>
) => {
  const sorted = sortBidsDescending(playerBids);
  return sorted.length ? sortBidsDescending(playerBids)[0] : null;
};

export function distributeCards(
  players: Player[],
  round: number,
  deck: Card[]
) {
  const numPlayers = players.length;
  const numCardsMap: { [key: number]: { [key: number]: number } } = {
    1: { 3: 10, 4: 9, 5: 8 },
    2: { 3: 6, 4: 4, 5: 3 },
    3: { 3: 6, 4: 4, 5: 3 },
    4: { 3: 0, 4: 0, 5: 0 },
  };

  const numCards = numCardsMap[round][numPlayers];

  shuffleCards(deck);

  for (let i = 0; i < numCards; i++) {
    players.forEach((player) => {
      const card = deck.pop();
      if (card) {
        player.hand.push(card);
      }
    });
  }
}

export function buyPainting(
  auctioneer: Player,
  auctionWinner: Player,
  selectedCards: Card[],
  auctionCost: number
): void {
  // Add the selected cards to the target player's paintingsBought array
  auctionWinner.paintingsBought.push(...selectedCards);

  // Perform the transaction
  if (auctioneer === auctionWinner) {
    auctioneer.money -= auctionCost;
  } else {
    auctionWinner.money -= auctionCost;
    auctioneer.money += auctionCost;
  }
}

export function sellPaintings(
  players: Player[],
  colorValues: Record<Color, number>
) {
  players.forEach((player) => {
    player.paintingsBought.forEach((card) => {
      player.money += colorValues[Artists[card.artwork.artistId].color];
    });

    player.paintingsBought = [];
  });
}

export function initializeColorValues(): Record<Color, number> {
  return {
    yellow: 0,
    green: 0,
    orange: 0,
    red: 0,
    blue: 0,
  };
}

export function calculateColorValues(
  boughtPaintings: Card[],
  unsoldPaintings: Card[]
): Record<Color, number> {
  const auctionedPerColorCount: Record<Color, number> = {
    yellow: 0,
    green: 0,
    orange: 0,
    red: 0,
    blue: 0,
  };

  [...boughtPaintings, ...unsoldPaintings].forEach((painting) => {
    const color = Artists[painting.artwork.artistId].color;
    auctionedPerColorCount[color]++;
  });

  const sortedColors = (Object.keys(auctionedPerColorCount) as Color[]).sort(
    (colorA, colorB) => {
      const countA = auctionedPerColorCount[colorA];
      const countB = auctionedPerColorCount[colorB];

      if (countA === countB) {
        // Handle tie by comparing priority
        return colorPriority.indexOf(colorA) - colorPriority.indexOf(colorB);
      }

      return countB - countA; // Sort by count in descending order
    }
  );

  console.log("Colors sorted from most to least:", sortedColors);

  const colorValues: Record<Color, number> = {
    yellow: 0,
    green: 0,
    orange: 0,
    red: 0,
    blue: 0,
  };

  const colorsThatWereAuctioned = sortedColors.filter(
    (c, i) => auctionedPerColorCount[c] > 0 && i < 3
  );

  colorsThatWereAuctioned.forEach((color, index) => {
    colorValues[color] = 30 - index * 10;
  });

  return colorValues;
}

export const calculatePlayerAngle = (
  playerIndex: number,
  totalPlayers: number
) => {
  if (totalPlayers === 2) {
    return playerIndex === 0 ? 315 : 45;
  } else if (totalPlayers === 3) {
    return [315, 0, 45][playerIndex];
  } else if (totalPlayers === 4) {
    return [300, 345, 15, 60][playerIndex];
  } else {
    return 0;
  }
};

export const calculatePositionOnOval = (
  angle: number,
  ovalValues: OvalValues
) => {
  const ovalRadiusX = ovalValues.ovalRadiusX;
  const ovalRadiusY = ovalValues.ovalRadiusY;

  const radians = angle * (Math.PI / 180);
  const offsetX = ovalRadiusX * Math.cos(radians);
  const offsetY = ovalRadiusY * Math.sin(radians);

  return {
    x: ovalValues.centerX + offsetX,
    y: ovalValues.centerY + offsetY,
  };
};

export const getOvalValues = (): OvalValues => {
  const width = 2560;
  const height = 1329.33;
  const aspectRatio = width / height;

  const centerX = width / 2;
  const centerY = height / 2;

  const ovalWidth = 2150.4;
  const ovalHeight = 1076.53;

  const ovalRadiusX = (ovalWidth / 3.8) * aspectRatio;
  const ovalRadiusY = (ovalHeight / 3.8) * aspectRatio;

  return {
    centerX: centerX,
    centerY: centerY,
    ovalRadiusX: ovalRadiusX,
    ovalRadiusY: ovalRadiusY,
  };
};

export interface OvalValues {
  centerX: number;
  centerY: number;
  ovalRadiusX: number;
  ovalRadiusY: number;
}
