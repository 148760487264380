import React, { useContext } from "react";
import { Artists, Card, startDeck } from "../data/cardData";
import GameStateContext from "../GameStateContext";
import { MAX_ROUND_COUNT } from "../data/Round";
import {
  calculateColorValues,
  initializeColorValues,
} from "../logic/GameActions";

const GameBoard: React.FC = () => {
  const { rounds, gamePlayers, auctionSelectedCardIds } =
    useContext(GameStateContext);

  const renderHeaderRow = () => {
    return (
      <tr>
        <th>{/* Empty header for the arrow column */}</th>
        {Object.values(Artists).map((artist) => (
          <th
            key={artist.name}
            className="header-cell-card-artist content"
            style={{
              backgroundColor: artist.colorHex,
            }}
          >
            {artist.name}
          </th>
        ))}
      </tr>
    );
  };

  const renderContentRows = () => {
    const rows = [];

    const auctionCards = auctionSelectedCardIds.map((id) => startDeck[id]);
    const boughtPaintings = gamePlayers
      .flatMap((player) => player.paintingsBought)
      .concat(auctionCards);
    const unsoldPaintings: Card[] = [];

    for (let i = 0; i < MAX_ROUND_COUNT; i++) {
      const round = rounds.length > i ? rounds[i] : null;
      const rowNumber = i + 1;
      const currentRoundMarker =
        rowNumber === rounds.length ? ">" : "\u00A0\u00A0\u00A0";

      const rowClass = rowNumber === rounds.length ? "current-round" : "";

      const colorValues =
        rowNumber === rounds.length
          ? calculateColorValues(boughtPaintings, unsoldPaintings)
          : round?.colorValues ?? initializeColorValues();

      rows.push(
        <tr key={i} className={`token-tr ${rowClass}`}>
          <td className="marker-td">{currentRoundMarker}</td>
          {Object.values(Artists).map((artist) => {
            const colorValue = colorValues[artist.color] ?? 0;
            const tokenClass =
              colorValue !== 0
                ? `token token-value-${colorValue}`
                : "token token-value-0";

            return (
              <td key={artist.name}>
                <span className={tokenClass}>{colorValue}</span>
              </td>
            );
          })}
        </tr>
      );
    }

    return rows;
  };

  return (
    <table className="ui-gameboard-table">
      <thead className="ui-gameboard-thead">{renderHeaderRow()}</thead>
      <tbody className="ui-gameboard-tbody">{renderContentRows()}</tbody>
    </table>
  );
};

export default GameBoard;
