import { useContext, useMemo, useState } from "react";
import { AuctionLogic } from ".";
import GameStateContext, {
  GameState,
  PassAuctionPlayer,
} from "../../GameStateContext";
import { Player } from "../../data/Player";
import { startDeck } from "../../data/cardData";
import { buyPainting, findHighestBidder } from "../GameActions";
import {
  serializeCard,
  serializeCards,
  serializeMoney,
  serializePlayer,
} from "../LogSerialization";

function highestBidderWithoutActivePlayer(
  playerBids: Record<string, number>,
  activePlayerName: string
) {
  // Whoever put the card up for auction has submitted a bid
  // We want to find if someone ELSE also submitted a bid, so we con't consider the activePlayer when looking for the highest bid
  const playerBidsWithoutActivePlayer = { ...playerBids };
  delete playerBidsWithoutActivePlayer[activePlayerName];

  return findHighestBidder(playerBidsWithoutActivePlayer);
}

function afterBid(
  gameState: GameState,
  newPlayerBids: Record<string, number>,
  auctionPlayerIndex: number | null,
  setAuctionPlayerIndex: React.Dispatch<number | null>
): boolean {
  // If everyone had a chance to bid, end the auction
  if (Object.keys(newPlayerBids).length === gameState.gamePlayers.length)
    return true;

  // If someone other than the auctioneer submitted a non-zero bid, end the auction
  const highest = highestBidderWithoutActivePlayer(
    newPlayerBids,
    gameState.activePlayer.name
  );
  if (highest?.bid) {
    return true;
  }

  // If the auction isn't over, pass the "auction turn" to the next player
  PassAuctionPlayer(
    gameState.gamePlayers,
    auctionPlayerIndex,
    setAuctionPlayerIndex
  );

  return false;
}

function endAuction(
  gameState: GameState,
  newPlayerBids: Record<string, number>
) {
  // If someone other than the auctioneer submitted a non-zero bid, they win
  let highest = highestBidderWithoutActivePlayer(
    newPlayerBids,
    gameState.activePlayer.name
  );

  if (!highest?.bid) {
    // No one except the auctioneer submitted a non-zero bid, the auctioneer wins the auction. Use the default findHighestBidder.
    highest = findHighestBidder(newPlayerBids);
  }

  if (!highest?.bid) {
    throw new Error("Highest non-zero bid not found");
  }

  const highestBidder = gameState.gamePlayers.find(
    (p) => p.name === highest!.playerName
  );

  if (!highestBidder) {
    throw new Error("Highest bidder not found");
  }

  const auctionSelectedCards = gameState.auctionSelectedCardIds.map(
    (id) => startDeck[id]
  );

  buyPainting(
    gameState.activePlayer,
    highestBidder,
    auctionSelectedCards,
    highest.bid
  );

  gameState.addLog(
    serializePlayer(highestBidder, gameState.gamePlayers),
    "wins",
    serializeCards(auctionSelectedCards),
    "for",
    serializeMoney(highest.bid)
  );
}

interface RenderAuctionDialogContentProps {
  player: Player;
  playerIndex: number;
}

const RenderAuctionDialogContent: React.FC<RenderAuctionDialogContentProps> = ({
  player,
  playerIndex,
}) => {
  const gameState = useContext(GameStateContext);
  const [bidAmount, setBidAmount] = useState("");

  const isSubmitted = useMemo(
    () => player.name in gameState.playerBids,
    [player.name, gameState.playerBids]
  );

  const currentHighestBid = Object.values(gameState.playerBids).reduce(
    (maxBid, bidAmount) => Math.max(maxBid, bidAmount),
    0
  );

  const auctionPlayer =
    gameState.auctionPlayerIndex != null
      ? gameState.gamePlayers[gameState.auctionPlayerIndex]
      : null;

  const handlePass = () => {
    gameState.submitPlayerBid(player, 0);
  };

  const handleBidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBidAmount = parseInt(event.target.value) || "";
    setBidAmount(newBidAmount.toString());
  };

  const handleBidSubmit = () => {
    gameState.submitPlayerBid(player, parseInt(bidAmount));
    setBidAmount("");
  };

  const isValidBid =
    bidAmount !== "" &&
    parseInt(bidAmount) >= 0 &&
    parseInt(bidAmount) <= player.money &&
    parseInt(bidAmount) > currentHighestBid;

  return (
    <>
      <b>FIXED PRICE AUCTION:</b>
      {Object.keys(gameState.playerBids).length > 0 ? (
        <div>
          {gameState.gamePlayers[gameState.activePlayerIndex].name}'s announced
          price: ${currentHighestBid}
        </div>
      ) : null}

      <div>To act: {auctionPlayer?.name}</div>

      {gameState.activePlayerIndex === playerIndex &&
      gameState.auctionPlayerIndex === playerIndex ? (
        <>
          <div>
            Set an initial price for this item. Other players must either accept
            this price or pass. If nobody buys it, the auctioneer must buy it.
          </div>
          <label>
            Bid Amount:
            <input
              type="number"
              value={bidAmount}
              onChange={handleBidChange}
              onKeyDown={(e) => {
                if (e.key === "Enter" && isValidBid) {
                  handleBidSubmit();
                }
              }}
              style={{ width: "40px" }}
            />
          </label>
          <button onClick={handleBidSubmit} disabled={!isValidBid}>
            Submit Bid
          </button>
        </>
      ) : null}
      {gameState.activePlayerIndex !== playerIndex &&
      gameState.auctionPlayerIndex === playerIndex ? (
        <div>
          <button
            disabled={player.money < currentHighestBid}
            onClick={() => gameState.submitPlayerBid(player, currentHighestBid)}
          >
            Buy Painting
          </button>
          <button onClick={handlePass}> Pass </button>
        </div>
      ) : null}
    </>
  );
};

const logic: AuctionLogic = {
  afterBid,
  endAuction,
  RenderAuctionDialogContent,
};

export default logic;
