import { useContext, useMemo, useState } from "react";
import { AuctionLogic } from ".";
import GameStateContext, { GameState } from "../../GameStateContext";
import { Player } from "../../data/Player";
import { startDeck } from "../../data/cardData";
import { buyPainting, findHighestBidder } from "../GameActions";
import {
  serializeCard,
  serializeCards,
  serializeMoney,
  serializePlayer,
} from "../LogSerialization";

function afterBid(
  gameState: GameState,
  newPlayerBids: Record<string, number>,
  _auctionPlayerIndex: number | null,
  _setAuctionPlayerIndex: React.Dispatch<number | null>
): boolean {
  // Everyone bids at once, the auction ends once everyone has submitted a bid
  return Object.keys(newPlayerBids).length === gameState.gamePlayers.length;
}

function endAuction(
  gameState: GameState,
  newPlayerBids: Record<string, number>
) {
  const highest = findHighestBidder(newPlayerBids);
  const auctionSelectedCards = gameState.auctionSelectedCardIds.map(
    (id) => startDeck[id]
  );

  function playerBidsToString(playerBids: Record<string, number>) {
    return Object.entries(playerBids)
      .map(([playerName, bid]) => {
        const player = gameState.gamePlayers.find((p) => p.name === playerName);

        if (!player) {
          return `Unknown Player: $${bid}$`;
        }

        return `${serializePlayer(player, gameState.gamePlayers)}: $${bid}$`;
      })
      .join(", ");
  }

  const bidsToPrint = playerBidsToString(newPlayerBids);

  if (highest === null || highest.bid === 0) {
    // No bids were made or highest bid is 0, so the active player gets the painting for free
    buyPainting(
      gameState.activePlayer,
      gameState.activePlayer,
      auctionSelectedCards,
      0
    );

    gameState.addLog("Hidden auction bids:", bidsToPrint);

    gameState.addLog(
      "Highest bid is $0$, so ",
      serializePlayer(gameState.activePlayer, gameState.gamePlayers),
      "wins",
      serializeCards(auctionSelectedCards),
      "for free!"
    );

    return;
  }

  const highestBid = highest.bid;
  const highestBidders = gameState.gamePlayers.filter(
    (p) => newPlayerBids[p.name] === highestBid
  );

  if (highestBidders.includes(gameState.activePlayer)) {
    // Active player is one of the highest bidders, they win the auction
    buyPainting(
      gameState.activePlayer,
      gameState.activePlayer,
      auctionSelectedCards,
      highestBid
    );

    gameState.addLog("Hidden auction bids:", bidsToPrint);

    gameState.addLog(
      serializePlayer(gameState.activePlayer, gameState.gamePlayers),
      "wins",
      serializeCards(auctionSelectedCards),
      "for",
      serializeMoney(highestBid)
    );

    return;
  }

  if (highestBidders.length === 1) {
    // Only one highest bidder, they win the auction
    const highestBidder = highestBidders[0];
    buyPainting(
      gameState.activePlayer,
      highestBidder,
      auctionSelectedCards,
      highestBid
    );

    gameState.addLog("Hidden auction bids:", bidsToPrint);

    gameState.addLog(
      serializePlayer(highestBidder, gameState.gamePlayers),
      "wins",
      serializeCards(auctionSelectedCards),
      "for",
      serializeMoney(highestBid)
    );

    return;
  }

  // There is a tie between two players who are not the auctioneer: arbitrate that tie
  let highestTiedBidder = gameState.activePlayer;
  const gamePlayers = gameState.gamePlayers;
  const activePlayerIndex = gamePlayers.findIndex(
    (p) => p === gameState.activePlayer
  );
  const playerCount = gamePlayers.length;

  for (
    let i = activePlayerIndex + 1;
    i < activePlayerIndex + playerCount;
    i++
  ) {
    const bidderIndex = i % playerCount;
    const bidder = gamePlayers[bidderIndex];
    if (newPlayerBids[bidder.name] > newPlayerBids[highestTiedBidder.name]) {
      highestTiedBidder = bidder;
    }
  }

  buyPainting(
    gameState.activePlayer,
    highestTiedBidder,
    auctionSelectedCards,
    highestBid
  );

  gameState.addLog("Hidden auction bids:", bidsToPrint);

  gameState.addLog(
    serializePlayer(highestTiedBidder, gameState.gamePlayers),
    "wins",
    serializeCards(auctionSelectedCards),
    "in a tie for",
    serializeMoney(highestBid),
    "as they were closest to the auctioneer."
  );
}

interface RenderAuctionDialogContentProps {
  player: Player;
  playerIndex: number;
}

const RenderAuctionDialogContent: React.FC<RenderAuctionDialogContentProps> = ({
  player,
  playerIndex,
}) => {
  const gameState = useContext(GameStateContext);

  const bidCount = Object.keys(gameState.playerBids).length;
  const [bidAmount, setBidAmount] = useState(0);
  const playerCount = gameState.gamePlayers.length;
  const isSubmitted = useMemo(
    () => player.name in gameState.playerBids,
    [player.name, gameState.playerBids]
  );
  const handleBidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBidAmount(parseInt(event.target.value));
  };
  const handleBidSubmit = () => {
    gameState.submitPlayerBid(player, bidAmount);
    setBidAmount(0);
  };

  const isValidBid =
    bidAmount >= 0 && bidAmount <= player.money && !isSubmitted;

  return (
    <>
      <b>HIDDEN AUCTION:</b>
      <div>Secretly make only one bid for this painting!</div>

      <div>
        {bidCount}/{playerCount} players have submitted a bid.
      </div>
      <div>
        <label>
          Bid Amount:
          <input
            type="number"
            value={bidAmount}
            onChange={handleBidChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidBid) {
                handleBidSubmit();
              }
            }}
            style={{ width: "40px" }}
          />
        </label>
        <button onClick={handleBidSubmit} disabled={!isValidBid}>
          Submit Bid
        </button>
      </div>
    </>
  );
};

const logic: AuctionLogic = {
  afterBid,
  endAuction,
  RenderAuctionDialogContent,
};

export default logic;
