import React from "react";
import { Player } from "../data/Player";
import { Artists } from "../data/cardData";

interface PlayerPaintingsBoughtProps {
  player: Player;
}

const PlayerPaintingsBought: React.FC<PlayerPaintingsBoughtProps> = ({
  player,
}) => {
  const colorCounts: Record<string, number> = {};

  // Initialize colorCounts with 0 count for each color
  ["yellow", "blue", "red", "green", "orange"].forEach((color) => {
    colorCounts[color] = 0;
  });

  // Count the number of cards for each color
  player.paintingsBought.forEach((card) => {
    const artist = Artists[card.artwork.artistId];
    const color = artist.color;
    colorCounts[color] += 1;
  });

  return (
    <div className="player-paintings-bought">
      {Object.entries(colorCounts).map(([color, count]) => (
        <div
          key={color}
          className={`color-card ${color} ${count === 0 ? "transparent" : ""}`}
        >
          <span className="count">{count}</span>
        </div>
      ))}
    </div>
  );
};

export default PlayerPaintingsBought;
