import { useContext, useEffect, useState } from "react";
import GameStateContext from "../GameStateContext";
import CardImage from "./CardImage";
import { startDeck } from "../data/cardData";
import { Player } from "../data/Player";

export default function PlayerHandDemo({}: {}) {
  const { isGameOver } = useContext(GameStateContext);

  const player: Player = {
    name: "",
    position: 100,
    money: 0,
    hand: [
      startDeck[0],
      startDeck[68],
      startDeck[13],
      startDeck[34],
      startDeck[40],
      startDeck[12],
      startDeck[59],
    ],
    paintingsBought: [],
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Logic to determine if selection is disabled; especially for double type
  const isSelectingDisabled = true;

  const mid = Math.floor(player.hand.length / 2);
  let translateScale = 6;
  if (windowSize.width < 1024) {
    translateScale = 0.6;
  }

  return (
    <>
      <div className="card-grid">
        {player.hand.map((card, cardIndex) => {
          const cardWidth = `${windowSize.width / 2.5 / player.hand.length}px`;

          return (
            <div
              key={card.id}
              className={`card ${
                isSelectingDisabled || isGameOver ? "disabled" : ""
              }`}
              style={{ width: cardWidth }}
            >
              <CardImage
                style={{
                  zIndex: cardIndex,
                  transform: `rotate(${(cardIndex - mid) * 5}deg) translateY(${
                    translateScale * Math.pow(cardIndex - mid, 2)
                  }px)`,
                }}
                card={card}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
