import { Card, CardIds, Color, startDeck } from "./cardData";

export const MAX_ROUND_COUNT = 4;
export const MAX_PAINTINGS_PER_ARTIST = 5;
export const OPEN_AUCTION_TIMEOUT = 10 * 1000;
export const OPEN_AUCTION_COUNTDOWN_THRESHOLD = 10 * 1000;
export const MIN_PLAYER_COUNT = 3;
export const OPEN_AUCTION_PAUSE = 4000;

export interface Round {
  soldPaintings: Card[];
  unsoldPaintings: Card[];
  colorValues: Record<Color, number>;
}

export interface SharedRound {
  soldPaintingsCardIds: CardIds;
  unsoldPaintingsCardIds: CardIds;
  colorValues: Record<Color, number>;
}

export const roundToShared = (round: Round) => {
  const sharedPlayer: SharedRound = {
    colorValues: round.colorValues,
    soldPaintingsCardIds: round.soldPaintings.map((c) => c.id),
    unsoldPaintingsCardIds: round.unsoldPaintings.map((c) => c.id),
  };
  return sharedPlayer;
};

export const sharedToRound = (sharedRound: SharedRound) => {
  const round: Round = {
    colorValues: sharedRound.colorValues,
    soldPaintings: sharedRound.soldPaintingsCardIds.map((id) => startDeck[id]),
    unsoldPaintings: sharedRound.unsoldPaintingsCardIds.map(
      (id) => startDeck[id]
    ),
  };
  return round;
};
