import { GameState } from "../../GameStateContext";
import { AuctionType } from "../../data/cardData";

import FixedPrice from "./FixedPrice";
import Hidden from "./Hidden";
import OneOffer from "./OneOffer";
import Open from "./Open";
import Double from "./Double";
import { Player } from "../../data/Player";

export interface AuctionLogic {
  afterBid: (
    gameState: GameState,
    newPlayerBids: Record<string, number>,
    auctionPlayerIndex: number | null,
    setAuctionPlayerIndex: React.Dispatch<number | null>
  ) => boolean;
  endAuction: (
    gameState: GameState,
    newPlayerBids: Record<string, number>
  ) => void;
  RenderAuctionDialogContent: React.FC<{
    player: Player;
    playerIndex: number;
  }>;
}

const auctionLogics: Record<AuctionType, AuctionLogic> = {
  open: Open,
  oneoffer: OneOffer,
  hidden: Hidden,
  fixedprice: FixedPrice,
  double: Double,
};

export default auctionLogics;
