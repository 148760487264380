export type Color = "yellow" | "green" | "orange" | "red" | "blue";
export type AuctionType =
  | "open"
  | "oneoffer"
  | "hidden"
  | "fixedprice"
  | "double";
export type Deck = Card[];
export type CardIds = Card["id"][];

export interface Artist {
  name: string;
  color: Color;
  colorHex: string;
  colorHexSelected: string;
}

export interface Artwork {
  name: string;
  imgUrl: string;
  artistId: keyof typeof Artists; // Use keyof typeof to enforce artistId type safety
}

export interface Card {
  id: number;
  artwork: Artwork;
  auctionType: AuctionType;
}

export const colorPriority: Color[] = [
  "yellow",
  "blue",
  "red",
  "green",
  "orange",
];

export const Artists: Record<number, Artist> = {
  0: {
    name: "van Gogh",
    color: "yellow",
    colorHex: "#fff32b",
    colorHexSelected: "#fffa7a",
  },
  1: {
    name: "da Vinci",
    color: "blue",
    colorHex: "#4ac1ec",
    colorHexSelected: "#a7d8f1",
  },
  2: {
    name: "Monet",
    color: "red",
    colorHex: "#ee2e2b",
    colorHexSelected: "#f48482",
  },
  3: {
    name: "Picasso",
    color: "green",
    colorHex: "#70be4e",
    colorHexSelected: "#a1d68e",
  },
  4: {
    name: "Vermeer",
    color: "orange",
    colorHex: "#fcaa1a",
    colorHexSelected: "#ffd96a",
  },
};

export const startDeck: Deck = [
  {
    id: 0,
    artwork: {
      artistId: 0,
      imgUrl: "The Starry Night.jpg",
      name: "The Starry Night",
    },
    auctionType: "open",
  },
  {
    id: 1,
    artwork: {
      artistId: 0,
      imgUrl: "Self-Portrait of van Gogh.jpg",
      name: "Self-Portrait of van Gogh",
    },
    auctionType: "oneoffer",
  },
  {
    id: 2,
    artwork: {
      artistId: 0,
      imgUrl: "The Potato Eaters.jpg",
      name: "The Potato Eaters",
    },
    auctionType: "hidden",
  },
  {
    id: 3,
    artwork: {
      artistId: 0,
      imgUrl: "Starry Night over the Rhône.jpg",
      name: "La Nuit étoilée",
    },
    auctionType: "fixedprice",
  },
  {
    id: 4,
    artwork: {
      artistId: 0,
      imgUrl: "Sunflowers.jpg",
      name: "Sunflowers",
    },
    auctionType: "double",
  },
  {
    id: 5,
    artwork: {
      artistId: 0,
      imgUrl: "The Yellow House (The Street).jpg",
      name: "The Yellow House",
    },
    auctionType: "open",
  },
  {
    id: 6,
    artwork: {
      artistId: 0,
      imgUrl: "Café Terrace at Night.jpg",
      name: "Café Terrace at Night",
    },
    auctionType: "oneoffer",
  },
  {
    id: 7,
    artwork: {
      artistId: 0,
      imgUrl: "Red Vineyard at Arles.jpg",
      name: "Red Vineyard at Arles",
    },
    auctionType: "hidden",
  },
  {
    id: 8,
    artwork: {
      artistId: 0,
      imgUrl: "The Church at Auvers.jpg",
      name: "The Church at Auvers",
    },
    auctionType: "fixedprice",
  },
  {
    id: 9,
    artwork: {
      artistId: 0,
      imgUrl: "Wheatfield with Crows.jpg",
      name: "Wheatfield with Crows",
    },
    auctionType: "double",
  },
  {
    id: 10,
    artwork: {
      artistId: 0,
      imgUrl: "Irises.jpg",
      name: "Irises",
    },
    auctionType: "open",
  },
  {
    id: 11,
    artwork: {
      artistId: 0,
      imgUrl: "Portrait of Dr. Gachet.jpg",
      name: "Portrait of Dr. Gachet",
    },
    auctionType: "oneoffer",
  },
  {
    id: 12,
    artwork: {
      artistId: 1,
      imgUrl: "Mona Lisa.jpg",
      name: "Mona Lisa",
    },
    auctionType: "open",
  },
  {
    id: 13,
    artwork: {
      artistId: 1,
      imgUrl: "The Last Supper.jpg",
      name: "The Last Supper",
    },
    auctionType: "oneoffer",
  },
  {
    id: 14,
    artwork: {
      artistId: 1,
      imgUrl: "Vitruvian Man.jpg",
      name: "Vitruvian Man",
    },
    auctionType: "hidden",
  },
  {
    id: 15,
    artwork: {
      artistId: 1,
      imgUrl: "La Belle Ferroniere.jpg",
      name: "La Belle Ferroniere",
    },
    auctionType: "fixedprice",
  },
  {
    id: 16,
    artwork: {
      artistId: 1,
      imgUrl: "The Baptism of Christ.jpg",
      name: "The Baptism of Christ",
    },
    auctionType: "open",
  },
  {
    id: 17,
    artwork: {
      artistId: 1,
      imgUrl: "The Annunciation.jpg",
      name: "The Annunciation",
    },
    auctionType: "double",
  },
  {
    id: 18,
    artwork: {
      artistId: 1,
      imgUrl: "Head of a Woman (La Scapigliata).jpg",
      name: "La Scapigliata",
    },
    auctionType: "oneoffer",
  },
  {
    id: 19,
    artwork: {
      artistId: 1,
      imgUrl: "Lady with an Ermine.jpg",
      name: "Lady with an Ermine",
    },
    auctionType: "hidden",
  },
  {
    id: 20,
    artwork: {
      artistId: 1,
      imgUrl: "Salvator Mundi.jpg",
      name: "Salvator Mundi",
    },
    auctionType: "fixedprice",
  },
  {
    id: 21,
    artwork: {
      artistId: 1,
      imgUrl: "Ginevra de Benci.jpg",
      name: "Ginevra de Benci",
    },
    auctionType: "double",
  },
  {
    id: 22,
    artwork: {
      artistId: 1,
      imgUrl: "The Virgin and Child with Saint Anne.jpg",
      name: "Virgin and Child",
    },
    auctionType: "open",
  },
  {
    id: 23,
    artwork: {
      artistId: 1,
      imgUrl: "Madonna and the Child.jpg",
      name: "Madonna and the Child",
    },
    auctionType: "fixedprice",
  },
  {
    id: 24,
    artwork: {
      artistId: 1,
      imgUrl: "St. John the Baptist.jpg",
      name: "St. John the Baptist",
    },
    auctionType: "hidden",
  },
  {
    id: 25,
    artwork: {
      artistId: 2,
      imgUrl: "Impression Sunrise.jpg",
      name: "Impression Sunrise",
    },
    auctionType: "open",
  },
  {
    id: 26,
    artwork: {
      artistId: 2,
      imgUrl: "The Water Lily Pond.jpg",
      name: "The Water Lily Pond",
    },
    auctionType: "oneoffer",
  },
  {
    id: 27,
    artwork: {
      artistId: 2,
      imgUrl: "Poppy fields near Argenteuil.jpg",
      name: "Poppy Fields",
    },
    auctionType: "hidden",
  },
  {
    id: 28,
    artwork: {
      artistId: 2,
      imgUrl: "Morning on the Seine near Giverny.jpg",
      name: "Morning on the Seine",
    },
    auctionType: "fixedprice",
  },
  {
    id: 29,
    artwork: {
      artistId: 2,
      imgUrl: "The Magpie.jpg",
      name: "The Magpie",
    },
    auctionType: "double",
  },
  {
    id: 30,
    artwork: {
      artistId: 2,
      imgUrl: "La Grenouillère.jpg",
      name: "La Grenouillère",
    },
    auctionType: "open",
  },
  {
    id: 31,
    artwork: {
      artistId: 2,
      imgUrl: "Haystacks.jpg",
      name: "Haystacks",
    },
    auctionType: "oneoffer",
  },
  {
    id: 32,
    artwork: {
      artistId: 2,
      imgUrl: "Garden at Sainte-Adresse.jpg",
      name: "Garden at Sainte-Adresse",
    },
    auctionType: "hidden",
  },
  {
    id: 33,
    artwork: {
      artistId: 2,
      imgUrl: "Boulevard des Capucines.jpg",
      name: "Boulevard des Capucines",
    },
    auctionType: "fixedprice",
  },
  {
    id: 34,
    artwork: {
      artistId: 2,
      imgUrl: "The Stroll.jpg",
      name: "The Stroll",
    },
    auctionType: "double",
  },
  {
    id: 35,
    artwork: {
      artistId: 2,
      imgUrl: "Houses of Parliament.jpg",
      name: "Houses of Parliament",
    },
    auctionType: "open",
  },
  {
    id: 36,
    artwork: {
      artistId: 2,
      imgUrl: "Stormy Sea in Étretat.jpg",
      name: "Stormy Sea in Étretat",
    },
    auctionType: "oneoffer",
  },
  {
    id: 37,
    artwork: {
      artistId: 2,
      imgUrl: "Camille Monet on a Garden Bench.jpg",
      name: "Camille Monet",
    },
    auctionType: "hidden",
  },
  {
    id: 38,
    artwork: {
      artistId: 4,
      imgUrl: "View of Delft.jpg",
      name: "View of Delft",
    },
    auctionType: "open",
  },
  {
    id: 39,
    artwork: {
      artistId: 2,
      imgUrl: "Arrival of the Normandy Train.jpg",
      name: "The Normandy Train",
    },
    auctionType: "fixedprice",
  },
  {
    id: 40,
    artwork: {
      artistId: 3,
      imgUrl: "Blue Nude.jpg",
      name: "Blue Nude",
    },
    auctionType: "open",
  },
  {
    id: 41,
    artwork: {
      artistId: 3,
      imgUrl: "La Vie.jpg",
      name: "La Vie",
    },
    auctionType: "oneoffer",
  },
  {
    id: 42,
    artwork: {
      artistId: 3,
      imgUrl: "The Old Guitarist.jpg",
      name: "The Old Guitarist",
    },
    auctionType: "hidden",
  },
  {
    id: 43,
    artwork: {
      artistId: 3,
      imgUrl: "La Soupe.jpg",
      name: "La Soupe",
    },
    auctionType: "fixedprice",
  },
  {
    id: 44,
    artwork: {
      artistId: 3,
      imgUrl: "Les Demoiselles d’Avignon.jpg",
      name: "The Ladies of Avignon",
    },
    auctionType: "double",
  },
  {
    id: 45,
    artwork: {
      artistId: 3,
      imgUrl: "Lady With a Fan.jpg",
      name: "Lady With a Fan",
    },
    auctionType: "open",
  },
  {
    id: 46,
    artwork: {
      artistId: 3,
      imgUrl: "Three Musicians.jpg",
      name: "Three Musicians",
    },
    auctionType: "oneoffer",
  },
  {
    id: 47,
    artwork: {
      artistId: 3,
      imgUrl: "Femme Accroupie.jpg",
      name: "Femme Accroupie",
    },
    auctionType: "hidden",
  },
  {
    id: 48,
    artwork: {
      artistId: 3,
      imgUrl: "Le Rêve.jpg",
      name: "Le Rêve",
    },
    auctionType: "fixedprice",
  },
  {
    id: 49,
    artwork: {
      artistId: 3,
      imgUrl: "Olga in an Armchair.jpg",
      name: "Olga in an Armchair",
    },
    auctionType: "double",
  },
  {
    id: 50,
    artwork: {
      artistId: 3,
      imgUrl: "L'Homme aux cartes.jpg",
      name: "L'Homme aux cartes",
    },
    auctionType: "open",
  },
  {
    id: 51,
    artwork: {
      artistId: 3,
      imgUrl: "The Weeping Woman.jpg",
      name: "The Weeping Woman",
    },
    auctionType: "oneoffer",
  },
  {
    id: 52,
    artwork: {
      artistId: 3,
      imgUrl: "Girl with a Mandolin.jpg",
      name: "Girl with a Mandolin",
    },
    auctionType: "hidden",
  },
  {
    id: 53,
    artwork: {
      artistId: 3,
      imgUrl: "Seated Woman.jpg",
      name: "Seated Woman",
    },
    auctionType: "fixedprice",
  },
  {
    id: 54,
    artwork: {
      artistId: 3,
      imgUrl: "Boy with a Pipe.jpg",
      name: "Boy with a Pipe",
    },
    auctionType: "double",
  },
  {
    id: 55,
    artwork: {
      artistId: 4,
      imgUrl: "A Girl Asleep.jpg",
      name: "A Girl Asleep",
    },
    auctionType: "open",
  },
  {
    id: 56,
    artwork: {
      artistId: 4,
      imgUrl: "A Lady Seated at a Virginal.jpg",
      name: "Lady Seated at a Virginal",
    },
    auctionType: "oneoffer",
  },
  {
    id: 57,
    artwork: {
      artistId: 4,
      imgUrl: "Allegory of the Catholic Faith.jpg",
      name: "The Allegory of Faith",
    },
    auctionType: "hidden",
  },
  {
    id: 58,
    artwork: {
      artistId: 4,
      imgUrl: "Girl Reading a Letter.jpg",
      name: "Girl Reading a Letter",
    },
    auctionType: "fixedprice",
  },
  {
    id: 59,
    artwork: {
      artistId: 4,
      imgUrl: "Girl with a Pearl Earring.jpg",
      name: "Girl with a Pearl Earring",
    },
    auctionType: "double",
  },
  {
    id: 60,
    artwork: {
      artistId: 4,
      imgUrl: "Girl with a Red Hat.jpg",
      name: "Girl with a Red Hat",
    },
    auctionType: "open",
  },
  {
    id: 61,
    artwork: {
      artistId: 4,
      imgUrl: "The Astronomer.jpg",
      name: "The Astronomer",
    },
    auctionType: "oneoffer",
  },
  {
    id: 62,
    artwork: {
      artistId: 4,
      imgUrl: "The Geographer.jpg",
      name: "The Geographer",
    },
    auctionType: "hidden",
  },
  {
    id: 63,
    artwork: {
      artistId: 4,
      imgUrl: "The Guitar Player.jpg",
      name: "The Guitar Player",
    },
    auctionType: "fixedprice",
  },
  {
    id: 64,
    artwork: {
      artistId: 4,
      imgUrl: "The Lacemaker.jpg",
      name: "The Lacemaker",
    },
    auctionType: "double",
  },
  {
    id: 65,
    artwork: {
      artistId: 4,
      imgUrl: "The Little Street.jpg",
      name: "The Little Street",
    },
    auctionType: "open",
  },
  {
    id: 66,
    artwork: {
      artistId: 4,
      imgUrl: "The Loveletter.jpg",
      name: "The Loveletter",
    },
    auctionType: "oneoffer",
  },
  {
    id: 67,
    artwork: {
      artistId: 4,
      imgUrl: "The Milkmaid.jpg",
      name: "The Milkmaid",
    },
    auctionType: "hidden",
  },
  {
    id: 68,
    artwork: {
      artistId: 4,
      imgUrl: "The Music Lesson.jpg",
      name: "The Music Lesson",
    },
    auctionType: "fixedprice",
  },
  {
    id: 69,
    artwork: {
      artistId: 4,
      imgUrl: "Woman Holding a Balance.jpg",
      name: "Woman Holding a Balance",
    },
    auctionType: "double",
  },
];
