import React, { useContext, forwardRef } from "react";
import GameStateContext from "../GameStateContext";
import { Player } from "../data/Player";

interface PlayerIconProps {
  playerIndex: number;
  player: Player;
}

const PlayerIcon: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PlayerIconProps
> = ({ playerIndex, player }, ref) => {
  const { activePlayerIndex } = useContext(GameStateContext);
  const isActivePlayer = activePlayerIndex === playerIndex;

  return (
    <div ref={ref} className={`player-icon ${isActivePlayer ? "active" : ""}`}>
      <p>{player.name}</p>
    </div>
  );
};

export default forwardRef<HTMLDivElement, PlayerIconProps>(PlayerIcon);
