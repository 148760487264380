import React, { useContext } from "react";
import GameStateContext from "../GameStateContext";
import {
  unserializePlayer,
  unserializeCard,
  unserializeMoney,
  unserializeColor,
} from "../logic/LogSerialization";
import { Artists } from "../data/cardData";

const GameLog: React.FC = () => {
  const { logs, gamePlayers } = useContext(GameStateContext);

  const parseLogEntry = (log: string) => {
    const playerRegex = /%(\d+)%/g;
    const cardRegex = /\[(\d+)\]/g;
    const moneyRegex = /\$(\d+)\$/g;
    const colorRegex = /\+(\d+)\+/g;

    let parsedLog = log.replace(playerRegex, (_, playerIndex) => {
      const player = unserializePlayer(`%${playerIndex}%`, gamePlayers);
      return player
        ? `<span class="log-player-name">${player.name}</span>`
        : "Unknown Player";
    });

    parsedLog = parsedLog.replace(cardRegex, (_, cardId) => {
      const card = unserializeCard(`[${cardId}]`);
      const artist = card ? Artists[card.artwork.artistId] : null;

      return artist && card
        ? `<span style="color: ${artist.colorHex}">${card.artwork.name}</span>`
        : "Unknown Card";
    });

    parsedLog = parsedLog.replace(moneyRegex, (_, amount) => {
      const money = unserializeMoney(`$${amount}$`);
      return money
        ? `<span class="log-money">$${money}</span>`
        : `<span class="log-money">$0</span>`;
    });

    parsedLog = parsedLog.replace(colorRegex, (_, artistId) => {
      const artist = unserializeColor(artistId);

      if (!artist) {
        return artistId;
      }

      return `<span style="color: ${artist.colorHex}"><b><span style="text-transform:uppercase">${artist.name}</span></b></span>`;
    });

    return <span dangerouslySetInnerHTML={{ __html: parsedLog }} />;
  };

  return (
    <div className="game-log">
      <ul className="gamelog-list">
        {logs.map((log, index) => (
          <li key={index}>{parseLogEntry(log)}</li>
        ))}
      </ul>
    </div>
  );
};

export default GameLog;
