import { useContext, useEffect, useState } from "react";
import { AuctionLogic } from ".";
import GameStateContext, { GameState } from "../../GameStateContext";
import { startDeck } from "../../data/cardData";
import { buyPainting, findHighestBidder } from "../GameActions";
import { Player } from "../../data/Player";
import {
  OPEN_AUCTION_COUNTDOWN_THRESHOLD,
  OPEN_AUCTION_PAUSE,
} from "../../data/Round";
import {
  serializeCard,
  serializeCards,
  serializeMoney,
  serializePlayer,
} from "../LogSerialization";

function afterBid(
  gameState: GameState,
  newPlayerBids: Record<string, number>,
  _auctionPlayerIndex: number | null,
  _setAuctionPlayerIndex: React.Dispatch<number | null>
): boolean {
  // BidSubmitTime is the time at which the auction was submitted, as a number, or it's null.
  const BidSubmitTime: number = new Date().getTime();
  gameState.setBidSubmitTime(BidSubmitTime);

  return false;
}

function endAuction(
  gameState: GameState,
  newPlayerBids: Record<string, number>
) {
  const highest = findHighestBidder(newPlayerBids);

  if (highest === null) {
    throw new Error("Highest bidder & bid not found");
  }

  const highestBidder = highest.bid
    ? gameState.gamePlayers.find((p) => p.name === highest.playerName)
    : gameState.activePlayer;

  if (!highestBidder) {
    throw new Error("Highest bidder not found");
  }

  const auctionSelectedCards = gameState.auctionSelectedCardIds.map(
    (id) => startDeck[id]
  );

  buyPainting(
    gameState.activePlayer,
    highestBidder,
    auctionSelectedCards,
    highest.bid
  );

  gameState.addLog(
    serializePlayer(highestBidder, gameState.gamePlayers),
    "wins",
    serializeCards(auctionSelectedCards),
    "for",
    serializeMoney(highest.bid)
  );
}

interface RenderAuctionDialogContentProps {
  player: Player;
  playerIndex: number;
}

const RenderAuctionDialogContent: React.FC<RenderAuctionDialogContentProps> = ({
  player,
  playerIndex,
}) => {
  const gameState = useContext(GameStateContext);
  const [countdown, setCountdown] = useState<number | null>(OPEN_AUCTION_PAUSE);
  const openAuctionSound = new Audio("/sounds/auctionopen.mp3");
  openAuctionSound.volume = 0.4;

  useEffect(() => {
    if (countdown !== null && countdown > 0) {
      const timerId = setInterval(() => {
        setCountdown((prev) => (prev !== null ? prev - 1000 : null));
      }, 1000);

      return () => clearInterval(timerId);
    } else if (countdown === 0) {
      // Play the sound when countdown reaches zero
      openAuctionSound.play();
      // Set countdown to null to prevent further countdowns
      setCountdown(null);
    }
  }, [countdown, openAuctionSound]);

  const [bidAmount, setBidAmount] = useState("");

  const currentHighestBid = Object.values(gameState.playerBids).reduce(
    (maxBid, bidAmount) => Math.max(maxBid, bidAmount),
    0
  );
  const currentHighestBidder = findHighestBidder(gameState.playerBids);

  const isPlayerHighestBidder =
    currentHighestBidder?.playerName === player.name;

  const handleBidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBidAmount = parseInt(event.target.value) || "";
    setBidAmount(newBidAmount.toString());
  };

  const handleBidSubmit = () => {
    gameState.submitPlayerBid(player, parseInt(bidAmount));
    setBidAmount("");
  };

  const isValidBid =
    bidAmount !== "" &&
    parseInt(bidAmount) >= 0 &&
    parseInt(bidAmount) <= player.money &&
    parseInt(bidAmount) > currentHighestBid &&
    !isPlayerHighestBidder;

  return (
    <>
      <div>
        <b>OPEN AUCTION:</b>
        {countdown !== null && countdown > 0 ? (
          <div>The auction is about to begin</div>
        ) : gameState.auctionTimeLeftInMs !== null &&
          gameState.auctionTimeLeftInMs >= 0 &&
          gameState.auctionTimeLeftInMs <
            OPEN_AUCTION_COUNTDOWN_THRESHOLD - 1000 ? (
          <div>
            Seconds left in the auction:{" "}
            {Math.ceil(gameState.auctionTimeLeftInMs / 1000)}
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
      </div>

      <div>
        Current Highest Bid: ${currentHighestBid}{" "}
        {currentHighestBidder ? `by ${currentHighestBidder.playerName}` : ""}
      </div>

      <div>
        <label>
          Bid Amount:
          <input
            type="number"
            value={bidAmount}
            onChange={handleBidChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidBid) {
                handleBidSubmit();
              }
            }}
            style={{ width: "40px" }}
            disabled={countdown !== null && countdown > 0}
          />
        </label>
        <button
          onClick={handleBidSubmit}
          disabled={!isValidBid || (countdown !== null && countdown > 0)}
        >
          Submit Bid
        </button>
      </div>
    </>
  );
};

const logic: AuctionLogic = {
  afterBid,
  endAuction,
  RenderAuctionDialogContent,
};

export default logic;
