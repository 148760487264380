import { Card, CardIds, startDeck } from "./cardData";

export interface Player {
  name: string;
  position: number;
  money: number;
  hand: Card[];
  paintingsBought: Card[];
}

export interface SharedPlayer {
  name: string;
  position: number;
  money: number;
  handCardIds: CardIds;
  paintingsBoughtCardIds: CardIds;
}

export const playerToShared = (player: Player) => {
  const sharedPlayer: SharedPlayer = {
    name: player.name,
    position: player.position,
    money: player.money,
    handCardIds: player.hand.map((c) => c.id),
    paintingsBoughtCardIds: player.paintingsBought.map((c) => c.id),
  };
  return sharedPlayer;
};

export const sharedToPlayer = (sharedPlayer: SharedPlayer) => {
  const player: Player = {
    name: sharedPlayer.name,
    position: sharedPlayer.position,
    money: sharedPlayer.money,
    hand: sharedPlayer.handCardIds.map((id) => startDeck[id]),
    paintingsBought: sharedPlayer.paintingsBoughtCardIds.map(
      (id) => startDeck[id]
    ),
  };
  return player;
};

export const demoInitialPlayerNames: string[] = [
  "Alice",
  "Bob",
  "Carol",
  "David",
  "Eve",
];
