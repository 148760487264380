import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./App.css";
import { startDeck } from "./data/cardData";
import GameStateContext from "./GameStateContext";
import GameBoard from "./components/GameBoard";
import PlayerHand from "./components/PlayerHand";
import PlayerAuctionDialog from "./components/PlayerAuctionDialog";
import GameOverScreen from "./components/GameOverScreen";
import GameLog from "./components/GameLog";
import PlayerIcon from "./components/PlayerIcon";
import PlayerPaintingsBought from "./components/PlayerPaintingsBought";
import { Player, demoInitialPlayerNames } from "./data/Player";
import CardImage from "./components/CardImage";
import HotPlayerInfo from "./components/HotPlayerInfo";
import { calculatePlayerAngle } from "./logic/GameActions";
import HelpBox from "./components/HelpBox";
import { MIN_PLAYER_COUNT } from "./data/Round";
import GavelAnimation from "./components/GavelAnimation";

export function Game() {
  const gameState = useContext(GameStateContext)!;
  const {
    gamePlayers,
    endRound,
    nextPlayer,
    auctionSelectedCardIds,
    isGameOver,
    addPlayer,
    rounds,
  } = gameState;

  const [desiredHotPlayerIndex, setHotPlayerIndex] = useState<number | null>(
    () => {
      const playerIndexParam = new URLSearchParams(window.location.search).get(
        "playerIndex"
      );
      if (!playerIndexParam) return null;
      return parseInt(playerIndexParam);
    }
  );

  const hotPlayerIndex = useMemo(() => {
    return desiredHotPlayerIndex != null &&
      desiredHotPlayerIndex < gamePlayers.length
      ? desiredHotPlayerIndex
      : null;
  }, [desiredHotPlayerIndex, gamePlayers]);

  const updateHotPlayerIndex = useCallback(
    (index: number) => {
      const url = new URL(window.location.href);
      url.searchParams.set("playerIndex", index.toString());
      window.history.replaceState({ ...window.history.state }, "", url.href);
      setHotPlayerIndex(index);
    },
    [setHotPlayerIndex]
  );

  const [playerNameInput, setPlayerNameInput] = useState("");
  const addPlayerWithName = useCallback(
    (name: string) => {
      const newPlayerIndex = addPlayer(name);
      if (hotPlayerIndex === null) {
        updateHotPlayerIndex(newPlayerIndex);
      }
    },
    [addPlayer, hotPlayerIndex, updateHotPlayerIndex]
  );

  const otherPlayers = useMemo(() => {
    let otherPlayersWithIndex: { player: Player; playerIndex: number }[] = [];

    if (hotPlayerIndex === null) {
      otherPlayersWithIndex = gamePlayers.map((player, playerIndex) => ({
        player,
        playerIndex,
      }));
      return otherPlayersWithIndex;
    }

    for (
      let nextPlayerToTheLeftIndex = hotPlayerIndex + 1;
      nextPlayerToTheLeftIndex < gamePlayers.length;
      ++nextPlayerToTheLeftIndex
    ) {
      otherPlayersWithIndex.push({
        player: gamePlayers[nextPlayerToTheLeftIndex],
        playerIndex: nextPlayerToTheLeftIndex,
      });
    }

    for (
      let nextPlayerToTheLeftIndex = 0;
      nextPlayerToTheLeftIndex < hotPlayerIndex;
      ++nextPlayerToTheLeftIndex
    ) {
      otherPlayersWithIndex.push({
        player: gamePlayers[nextPlayerToTheLeftIndex],
        playerIndex: nextPlayerToTheLeftIndex,
      });
    }

    return otherPlayersWithIndex;
  }, [gamePlayers, hotPlayerIndex]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const playerIconRef = useRef<HTMLDivElement | null>(null);
  const [playerIconSize, setPlayerIconSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (playerIconRef.current) {
      const { width, height } = playerIconRef.current.getBoundingClientRect();
      setPlayerIconSize({ width, height });
    }
  }, [windowSize, gamePlayers]);

  const getPointOnOval = (angle: number) => {
    const ovalWidth = windowSize.width * 0.88;
    const ovalHeight = ovalWidth / 2;

    const centerX = windowSize.width / 2;
    const centerY = windowSize.height / 2;

    const radians = (angle - 90) * (Math.PI / 180);

    const x = centerX + (ovalWidth / 2) * Math.cos(radians);
    const y = centerY + (ovalHeight / 2) * Math.sin(radians);

    return { x, y };
  };

  const hotPlayerAngle = 240;
  const { x: hotPlayerX, y: hotPlayerY } = getPointOnOval(hotPlayerAngle);
  const hotPlayerAdjustedX = hotPlayerX - playerIconSize.width;
  const hotPlayerAdjustedY = hotPlayerY - playerIconSize.height / 2;

  const playerNameValid = useMemo(() => {
    // Check name length
    if (
      !playerNameInput ||
      playerNameInput.length === 0 ||
      playerNameInput.length > 20
    ) {
      return false;
    }

    // Check if name contains only alphanumeric characters
    const alphanumericRegex = /^[a-z0-9]+$/i;
    if (!alphanumericRegex.test(playerNameInput)) {
      return false;
    }

    // Check duplicate names
    if (gamePlayers.some((p) => p.name === playerNameInput)) {
      return false;
    }

    return true;
  }, [playerNameInput, gamePlayers]);

  return (
    <>
      {/* OUTSIDE / ABOVE OVAL */}
      {otherPlayers.map((playerWithIndex, positionIndex) => {
        const angle = calculatePlayerAngle(positionIndex, otherPlayers.length);
        const { x, y } = getPointOnOval(angle);

        const adjustedX = x - playerIconSize.width;
        const adjustedY = y - playerIconSize.height / 2;

        const borderAnchorIcon: React.CSSProperties = {
          position: "absolute",
          left: `${adjustedX}px`,
          top: `${adjustedY}px`,
          zIndex: 50,
        };

        return (
          <div key={playerWithIndex.player.name} style={borderAnchorIcon}>
            <PlayerIcon
              ref={playerIconRef}
              player={playerWithIndex.player}
              playerIndex={playerWithIndex.playerIndex}
            />
            <PlayerPaintingsBought player={playerWithIndex.player} />
          </div>
        );
      })}

      <div
        style={{
          position: "absolute",
          left: hotPlayerAdjustedX,
          top: hotPlayerAdjustedY,
          zIndex: 50,
        }}
      >
        {hotPlayerIndex !== null && (
          <HotPlayerInfo playerIndex={hotPlayerIndex} />
        )}
      </div>
      <HelpBox />
      <GavelAnimation />
      {isGameOver ? (
        <div className="game-over-overlay">
          <GameOverScreen />
        </div>
      ) : null}
      <div className="ui-title">
        <div
          className={`ui-debug ${
            process.env.NODE_ENV === "production" ? "debug-hidden" : ""
          }`}
        >
          <button
            onClick={() =>
              addPlayerWithName(demoInitialPlayerNames[gamePlayers.length])
            }
            disabled={gamePlayers.length === demoInitialPlayerNames.length}
          >
            Add player
          </button>
          <button
            onClick={() => endRound([])}
            disabled={gamePlayers.length < MIN_PLAYER_COUNT}
          >
            {rounds.length ? "End round" : "Start game"}
          </button>
          <button onClick={nextPlayer}>Next player</button>
          <select
            onChange={(e) => {
              updateHotPlayerIndex(parseInt(e.currentTarget.value));
            }}
            value={hotPlayerIndex ?? ""}
          >
            {gamePlayers.map((player, playerIndex) => (
              <option key={player.name} value={playerIndex}>
                {player.name}
              </option>
            ))}
          </select>
        </div>
        <div className="ui-standard">
          {hotPlayerIndex !== null && rounds.length === 0 && (
            <button
              onClick={() => endRound([])}
              disabled={gamePlayers.length < MIN_PLAYER_COUNT}
            >
              Start game
            </button>
          )}
          {hotPlayerIndex === null && rounds.length === 0 && (
            <>
              <input
                type="text"
                value={playerNameInput}
                onChange={(e) => setPlayerNameInput(e.target.value)}
                placeholder="Enter player name"
                maxLength={20}
              />
              <button
                onClick={() => addPlayerWithName(playerNameInput)}
                disabled={!playerNameValid}
              >
                Join game
              </button>
            </>
          )}
        </div>
      </div>
      <div className="ui-gamelog">
        <GameLog />
      </div>
      {/* END OUTSIDE / ABOVE OVAL */}
      <div className="ui-oval-container">
        <div className="ui-oval">
          {/* CONTENTS OF OVAL */}

          <div className="ui-block1">
            <div className="ui-gameboard">
              <div className="ui-title">
                <h3>CLASSIC ART</h3>
              </div>

              <GameBoard />
            </div>
          </div>

          <div className="ui-block2">
            <div className="ui-auctioncards">
              {auctionSelectedCardIds.length > 0 && <p>Up for auction:</p>}
              <div className="auction-cards">
                {auctionSelectedCardIds.map((cardId) => (
                  <CardImage key={cardId} card={startDeck[cardId]} />
                ))}
              </div>
            </div>
          </div>

          <div className="ui-block3">
            <div className="ui-playerauctiondialog">
              {hotPlayerIndex !== null && (
                <PlayerAuctionDialog playerIndex={hotPlayerIndex} />
              )}
            </div>
          </div>

          {/* END OVAL */}
        </div>
      </div>
      <div className="ui-hand">
        {hotPlayerIndex !== null && <PlayerHand playerIndex={hotPlayerIndex} />}
      </div>
    </>
  );
}
