import React, { useState } from "react";
import { auctionIcons } from "./AuctionIcons";

const HelpBox = () => {
  const [showBox, setShowBox] = useState(false);

  const toggleBox = () => {
    setShowBox(!showBox);
  };

  return (
    <>
      <div className="help-box" onClick={toggleBox}>
        <div className="help-icon">?</div>
      </div>
      {showBox && (
        <div className="help-box-content">
          <h1
            style={{
              marginBottom: "1px",
              marginTop: "5px",
              fontFamily: "Roboto",
            }}
          >
            CLASSIC ART
          </h1>
          <i>by Reiner Knizia</i>
          <p style={{ textAlign: "justify" }}>
            Players take on the role of a museum that is trying to buy and sell
            paintings at the best price. The game is played over four rounds. On
            each player's turn they are the auctioneer, and put up one of the
            cards in their hand for auction. At the end of a round, the
            paintings are sold to the bank. The more that one artist’s work is
            purchased, the more valuable it becomes. Ties for popularity are
            broken to the left. The winner is the player who has the most money
            at the end of the four rounds.
          </p>
          <p style={{ textAlign: "justify" }}>
            As soon as one of the artists’ fifth painting is played on the
            table, the round ends and all the paintings that have been purchased
            in that round are sold to the bank. The value of a painting depends
            on the rank of its artist for the round. During the following
            rounds, the rank may change, causing the value of an artist’s
            painting to increase, decrease, or become worthless.
          </p>
          <p style={{ textAlign: "justify" }}>
            The most popular artist for a round is worth $30, the second $20 and
            the third $10. The other two artists are worth $0 that round. In
            subsequent rounds, the artist's value is cumulative for all rounds,
            except in the case where they are worth $0 this round- then they
            really are worth $0.
          </p>
          <table className="icon-table">
            <tbody>
              <tr>
                <td>
                  <div className="card-icon-help">{auctionIcons["open"]}</div>
                </td>
                <td className="help-text-cell">
                  <b>OPEN AUCTION</b>
                  <br />
                  All players (including the auctioneer) can bid in any order. A
                  timer will tick down until the end of the auction. If no
                  higher bids are made in that time, the person with the highest
                  bid wins the auction.
                </td>
              </tr>
              <tr>
                <td className="help-icon-cell">
                  <div className="card-icon-help">
                    {auctionIcons["oneoffer"]}
                  </div>
                </td>
                <td className="help-text-cell">
                  <b>ONE OFFER AUCTION</b>
                  <br />
                  One player starts the bidding. Then, submit a higher bid, or
                  pass to the next player around the table. The auctioneer bids
                  last. After all players have submitted, the highest bid wins.
                </td>
              </tr>
              <tr>
                <td>
                  <div className="card-icon-help">{auctionIcons["hidden"]}</div>
                </td>
                <td className="help-text-cell">
                  <b>HIDDEN AUCTION</b>
                  <br />
                  All players (including the auctioneer) simultaneously make one
                  bid in secret; the highest bid wins. If two or more players
                  tie for the highest bid, then the player sitting closest to
                  the auctioneer in clockwise order wins the auction. If the
                  auctioneer is one of the players who tied for the highest bid,
                  then they buy the painting
                </td>
              </tr>
              <tr>
                <td>
                  <div className="card-icon-help">
                    {auctionIcons["fixedprice"]}
                  </div>
                </td>
                <td className="help-text-cell">
                  <b>FIXED PRICE AUCTION</b>
                  <br />
                  The auctioneer declares a price for the painting. Each player,
                  starting with the player to the left of the auctioneer and
                  then continuing clockwise, can buy the painting at this price
                  (once someone buys it, the auction ends). If no one buys the
                  painting, the auctioneer MUST buy it for the fixed price.
                </td>
              </tr>
              <tr>
                <td>
                  <div className="card-icon-help">{auctionIcons["double"]}</div>
                </td>
                <td className="help-text-cell">
                  <b>DOUBLE AUCTION</b>
                  <br />
                  Two paintings are auctioned as a single item. The auction type
                  is determined by the second card, which must be by the same
                  artist as the first card. If the auctioneer can't (or chooses
                  not to) include a second card, the next player to their left
                  does instead. But that player becomes the new auctioneer and
                  will receive all the money from the sale of both paintings. If
                  that player can't, continue in this fashion. If no players
                  can, the original auctioneer wins the double card for free.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <i className="help-credits">
              Programming by Shane Rutherfoord-Jones with help from Chatty Geeps
              and Iggy Fisk
            </i>
          </p>
        </div>
      )}
    </>
  );
};

export default HelpBox;
