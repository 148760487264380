import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, onValue } from "firebase/database";
import { SharedGameState } from "./GameStateContext";
import { SharedRound } from "./data/Round";
import { SharedPlayer } from "./data/Player";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABZWR11KHocBm2XwWLMVPAEyzpALXQ3v8",
  authDomain: "classic-art-11ff9.firebaseapp.com",
  databaseURL:
    "https://classic-art-11ff9-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "classic-art-11ff9",
  storageBucket: "classic-art-11ff9.appspot.com",
  messagingSenderId: "95855822795",
  appId: "1:95855822795:web:95044832dbbcf11422d84e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const sendSharedState = (newState: SharedGameState, gameId: string) => {
  set(ref(database, `games/${gameId}`), newState);
};

export const onSharedStateChange = (
  callback: (newState: SharedGameState) => void,
  gameId: string
) => {
  const unsubscribe = onValue(ref(database, `games/${gameId}`), (snapshot) => {
    const data: Partial<SharedGameState> = snapshot.val();
    if (!data?.gamePlayers) return;

    // Firebase prunes empty arrays, so we need to re-initialize them
    const newState: SharedGameState = {
      activePlayerIndex: data.activePlayerIndex ?? 0,
      auctionPlayerIndex: data.auctionPlayerIndex ?? null,
      bidSubmitTime: data.bidSubmitTime ?? null,
      isGameOver: data.isGameOver!,
      deckCardIds: data.deckCardIds || [],
      auctionSelectedCardIds: data.auctionSelectedCardIds || [],
      playerBids: data.playerBids || {},
      logs: data.logs || [],
      rounds:
        data.rounds?.map((r: Partial<SharedRound>) => ({
          colorValues: r.colorValues!,
          soldPaintingsCardIds: r.soldPaintingsCardIds || [],
          unsoldPaintingsCardIds: r.unsoldPaintingsCardIds || [],
        })) || [],
      gamePlayers:
        data.gamePlayers?.map((player: Partial<SharedPlayer>) => ({
          name: player.name!,
          position: player.position!,
          money: player.money!,
          handCardIds: player.handCardIds || [],
          paintingsBoughtCardIds: player.paintingsBoughtCardIds || [],
        })) || [],
    };

    callback(newState);
  });

  return unsubscribe;
};
