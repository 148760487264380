import React from "react";
import { AuctionType, Card } from "../data/cardData";
import { auctionIcons } from "./AuctionIcons";

interface TooltipProps {
  card: Card;
}

const auctionTypeTip: Record<AuctionType, string> = {
  open: "OPEN: Real-time bidding",
  oneoffer: "ONE OFFER: Players take turns bidding",
  double: "DOUBLE: Two paintings as one item",
  hidden: "HIDDEN: Secret simultaneous bidding",
  fixedprice: "FIXED PRICE: Auctioneer sets a fixed price",
};

const CardTooltip: React.FC<TooltipProps> = ({ card }) => {
  const auctionTypeString = auctionTypeTip[card.auctionType];
  const colonIndex = auctionTypeString.indexOf(":");
  const boldAuctionType = auctionTypeString.substring(0, colonIndex + 1);
  const restOfTooltip = auctionTypeString.substring(colonIndex + 1);

  return (
    <div className="tooltip">
      <div className="tooltip-title-wrapper">
        <div className="tooltip-icon">{auctionIcons[card.auctionType]}</div>
        <strong>{boldAuctionType}</strong>
      </div>
      {restOfTooltip}
    </div>
  );
};

export default CardTooltip;
