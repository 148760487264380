import React, { useContext } from "react";
import GameStateContext from "../GameStateContext";

const GameOverScreen: React.FC = () => {
  const gameState = useContext(GameStateContext);

  // Access the GameState properties as needed
  const { gamePlayers } = gameState;

  // Find the player with the highest money
  let highestMoney = -Infinity;
  let winningPlayerName = "";

  gamePlayers.forEach((player) => {
    if (player.money > highestMoney) {
      highestMoney = player.money;
      winningPlayerName = player.name;
    }
  });

  return (
    <div>
      <h2>
        The winner is {winningPlayerName} with ${highestMoney}!
      </h2>
      <p>
        {gamePlayers.map((player) => (
          <div key={player.name}>
            <span
              style={{
                fontWeight: player.money === highestMoney ? "bold" : "normal",
              }}
            >
              {player.name} - ${player.money}
            </span>
          </div>
        ))}
      </p>
    </div>
  );
};

export default GameOverScreen;
