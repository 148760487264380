import React, { useContext, useState } from "react";
import GameStateContext from "../GameStateContext";
import PlayerIcon from "./PlayerIcon";
import PlayerPaintingsBought from "./PlayerPaintingsBought";
import { startDeck } from "../data/cardData";
import { incrementPlayerIndex } from "../logic/GameActions";
import { serializeCard, serializePlayer } from "../logic/LogSerialization";
import ConfirmationModal from "./ConfirmationModal";

export default function HotPlayerInfo({
  playerIndex,
}: {
  playerIndex: number;
}) {
  const {
    gamePlayers,
    setGamePlayers,
    activePlayerIndex,
    setAuctionSelectedCardIds,
    isAuctionInProgress,
    setAuctionPlayerIndex,
    roundShouldEnd,
    endRound,
    addLog,
    selectedCard,
    setSelectedCard,
    isConfirmationInProgress,
    setConfirmationInProgress,
  } = useContext(GameStateContext);

  const player = gamePlayers[playerIndex];

  const handleConfirm = () => {
    setConfirmationInProgress(false);
    if (!selectedCard) {
      throw new Error("No card selected when confirming");
      return;
    }
    player.hand = player.hand.filter((card) => card.id !== selectedCard.id);
    setGamePlayers(
      gamePlayers.map((p) => (p.name === player.name ? player : p))
    );
    setSelectedCard(null);

    if (roundShouldEnd(selectedCard)) {
      endRound([selectedCard]);
      return;
    }
  };

  const handleCancel = () => {
    setConfirmationInProgress(false);
  };

  const handleSubmit = () => {
    if (!selectedCard) {
      throw new Error("No card selected");
    }

    const shouldEndRound = roundShouldEnd(selectedCard);
    if (shouldEndRound) {
      setConfirmationInProgress(true);
      return;
    }

    const auctionSelectedCardId = selectedCard.id;
    player.hand = player.hand.filter((card) => card.id !== selectedCard.id);
    setGamePlayers(
      gamePlayers.map((p) => (p.name === player.name ? player : p))
    );
    setSelectedCard(null);

    //set auction player index based on auction type in switch
    switch (selectedCard.auctionType) {
      case "fixedprice":
        setAuctionPlayerIndex(activePlayerIndex);
        break;
      case "oneoffer":
        const nextAuctionPlayerIndex = incrementPlayerIndex(
          gamePlayers.length,
          activePlayerIndex
        );
        setAuctionPlayerIndex(nextAuctionPlayerIndex);
        break;
      case "double":
        setAuctionPlayerIndex(activePlayerIndex);
        break;
      default:
        break;
    }

    setAuctionSelectedCardIds([auctionSelectedCardId]);
    addLog(
      "Auction started by",
      serializePlayer(player, gamePlayers),
      ": ",
      serializeCard(startDeck[auctionSelectedCardId])
    );
  };

  return (
    <>
      <div>
        <div className="player-info">
          <PlayerIcon player={player} playerIndex={playerIndex} />
          <PlayerPaintingsBought player={player} />
        </div>
        <div className="ui-money">${gamePlayers[playerIndex].money}</div>
        {!isAuctionInProgress && playerIndex === activePlayerIndex && (
          <button
            disabled={!selectedCard || isConfirmationInProgress}
            onClick={handleSubmit}
            style={{ borderRadius: "8px" }}
          >
            Submit
          </button>
        )}
        {isConfirmationInProgress &&
          !isAuctionInProgress &&
          playerIndex === activePlayerIndex && (
            <ConfirmationModal
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}
      </div>
    </>
  );
}
