import React, { useCallback } from "react";
import "./App.css";
import { GameStateProvider } from "./GameStateContext";
import { Game } from "./Game";
import PlayerHandDemo from "./components/PlayerHandDemo";

function App() {
  const gameId = new URLSearchParams(window.location.search).get("gameId");
  const [gameIdInput, setGameIdInput] = React.useState("");
  const toGame = useCallback(() => {
    window.location.href = `/?gameId=${gameIdInput}`;
  }, [gameIdInput]);

  return (
    <div className="App">
      <div className="container">
        {gameId && (
          <GameStateProvider gameId={gameId}>
            <Game />
          </GameStateProvider>
        )}
        {!gameId && (
          <div className="game-creator">
            <div className="ui-title">
              <h3>CLASSIC ART</h3>
            </div>
            <input
              type="text"
              value={gameIdInput}
              onChange={(e) => setGameIdInput(e.target.value)}
              placeholder="Enter game ID"
              maxLength={20}
            />

            <button onClick={toGame}>Go to game</button>
            <button
              onClick={() =>
                setGameIdInput(Math.random().toString(36).substring(2, 15))
              }
            >
              Generate random
            </button>
          </div>
        )}
        {!gameId && (
          <div
            className="ui-hand"
            style={{
              position: "relative",
              marginTop: "2rem",
              marginBottom: "5rem",
              transform: "translateY(0%) translateX(-3.5%)",
            }}
          >
            <PlayerHandDemo />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
