import React, { ReactEventHandler } from "react";

interface ConfirmationModalProps {
  onConfirm: ReactEventHandler;
  onCancel: ReactEventHandler;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <p>Are you sure you want to end the round?</p>
        <div className="button-container">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
