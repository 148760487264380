import React, { useContext, useEffect, useRef, useState } from "react";
import "./GavelAnimation.css";
import GameStateContext from "../GameStateContext";

const GavelAnimation: React.FC = () => {
  const { isAuctionInProgress, gamePlayers, activePlayerIndex, rounds } =
    useContext(GameStateContext);
  const [isVisible, setIsVisible] = useState(false);
  const gavelbangSound = new Audio("/sounds/gavelbang.mp3");
  // const playerturnSound = new Audio("/sounds/playerturn.mp3");
  // const isCurrentPlayerTurn =
  //   gamePlayers[activePlayerIndex] === gamePlayers[hotPlayerIndex - 1]; // Why on earth is this -1 necessary

  const wasAuctionInProgress = useRef(isAuctionInProgress);
  // Main mode
  useEffect(() => {
    if (wasAuctionInProgress.current) {
      setIsVisible(true);

      setTimeout(() => {
        gavelbangSound.play();
      }, 240);

      setTimeout(() => {
        setIsVisible(false);
      }, 1200);
    }

    wasAuctionInProgress.current = isAuctionInProgress;
  }, [isAuctionInProgress]);

  // Debug mode, trigger on card selection

  // useEffect(() => {
  //   setIsVisible(true);
  //   const timeout = setTimeout(() => {
  //     setIsVisible(false);
  //   }, 1200);
  // }, [selectedCard]);

  //player turn sound to finish
  // const wasCurrentPlayerTurn = useRef(isCurrentPlayerTurn);
  // useEffect(() => {
  //   if (wasCurrentPlayerTurn.current && rounds.length) {
  //     playerturnSound.play();
  //   }

  //   wasCurrentPlayerTurn.current = isCurrentPlayerTurn;
  // }, [isCurrentPlayerTurn]);

  return isVisible ? <Animation /> : null;
};

const Animation: React.FC = () => {
  return (
    <div className="gavel-animation">
      <svg
        version="1.1"
        className="draw-hammer"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 52 39.2"
        enable-background="new 0 0 52 39.2"
      >
        <g className="hammer">
          <path
            className="hammer-fill shaft"
            fill="transparent"
            stroke-miterlimit="10"
            d="M48.4,36l-0.8,0.9c-1,1.2-2.9,1.3-4.3,0.2L43.2,37l-11-11.6
                        	c-0.4-0.4-0.5-1.1-0.1-1.5l0.8-1c0.4-0.4,1-0.5,1.5-0.1l13.2,8.8c0.1,0,0.1,0.1,0.1,0.1C49.2,32.9,49.4,34.8,48.4,36z"
          />
          <path
            className="head"
            fill="none"
            stroke-miterlimit="10"
            d="M22.5,1.9l-0.8,0.9c-0.2,0.2-0.2,0.5-0.2,0.5l0.2,0.8
                        	c0.1,0.3,0.1,0.6,0,0.9c-0.2,0.7-0.8,1.2-1.6,1.3l-1,0.1c-0.3,0-0.7,0.1-0.9,0.3l-4.8,5.7c0,0,0,0-0.1,0.1l-0.1,0.1
                        	c-0.1,0.2-0.1,0.4-0.1,0.6v0.9c0,1-0.6,1.8-1.6,1.9l-1,0.1c0,0-0.2,0-0.4,0.3l-0.8,0.9c-0.2,0.3-0.1,0.7,0.2,1l7.8,6.6
                        	c0.4,0.3,0.9,0.4,1.2,0.1l0.8-0.9c0.2-0.2,0.1-0.6,0.1-0.6l-0.2-0.8c-0.1-0.3-0.1-0.7,0-0.9c0.2-0.7,0.8-1.2,1.6-1.3l1.1-0.1
                        	c0.3,0,0.7,0,0.9-0.3l4.8-5.7c0,0-0.1,0.1,0,0c0,0,0.2-0.1,0.2-0.2c0.1-0.2,0.1-0.7,0.1-0.7l-0.1-0.9c0-1,0.6-1.8,1.6-1.9l1.1-0.1
                        	c0,0,0.3,0,0.5-0.2l0.8-0.9C32,9.2,32,8.4,31.7,8.1l-7.8-6.6C23.4,1.3,22.8,1.6,22.5,1.9z"
          />
          <path
            className="hammer-fill joint"
            fill="transparent"
            stroke-miterlimit="10"
            d="M31.6,21c-0.7-1.1-0.8-2.8-0.8-3.6c0-0.4-0.2-0.7-0.4-0.9l-0.8-0.7
                        	c-0.4-0.3-1-0.3-1.4,0.1l-3.8,4.5c-0.3,0.4-0.3,1,0.1,1.4l0.8,0.7c0.3,0.2,0.6,0.3,1,0.3c0.8-0.1,2.5-0.3,3.7,0.2
                        	c0.5,0.2,1-0.1,1.4-0.5l0,0C31.8,21.9,31.8,21.4,31.6,21z"
          />
        </g>
        <g className="base">
          <path
            className="hammer-fill"
            stroke-miterlimit="10"
            d="M25.2,38.6H1.9c-0.4,0-0.8-0.4-0.8-0.8V37c0-0.4,0.4-0.8,0.8-0.8
                        	h23.3c0.4,0,0.8,0.4,0.8,0.8v0.8C26,38.3,25.6,38.6,25.2,38.6z"
          />
          <path
            className="hammer-fill"
            stroke-miterlimit="10"
            d="M23.2,35.2H3.7c-0.4,0-0.7-0.3-0.7-0.7l0,0c0-0.4,0.3-0.7,0.7-0.7
                        	h19.6c0.4,0,0.7,0.3,0.7,0.7l0,0C23.9,34.9,23.6,35.2,23.2,35.2z"
          />
        </g>
      </svg>
    </div>
  );
};

export default GavelAnimation;
