// GameStateContext.tsx

import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import {
  Player,
  SharedPlayer,
  playerToShared,
  sharedToPlayer,
} from "./data/Player";
import {
  Card,
  Deck,
  startDeck,
  AuctionType,
  Artists,
  Color,
  CardIds,
} from "./data/cardData";
import {
  MAX_PAINTINGS_PER_ARTIST,
  MAX_ROUND_COUNT,
  OPEN_AUCTION_TIMEOUT,
  Round,
  SharedRound,
  roundToShared,
  sharedToRound,
} from "./data/Round";
import {
  determineStartingPlayer,
  distributeCards,
  incrementPlayerIndex,
  addPlayer as addPlayerLogic,
  sellPaintings,
  calculateColorValues,
  initializeColorValues,
} from "./logic/GameActions";
import auctionLogics from "./logic/auctions";

import { onSharedStateChange, sendSharedState } from "./Multiplayer";
import {
  serializeCard,
  serializeColor,
  serializeMoney,
  serializePlayer,
} from "./logic/LogSerialization";

export interface SharedGameState {
  gamePlayers: SharedPlayer[];
  activePlayerIndex: number;
  auctionPlayerIndex: number | null;
  deckCardIds: CardIds;
  rounds: SharedRound[];
  auctionSelectedCardIds: number[];
  playerBids: Record<Player["name"], number>;
  bidSubmitTime: number | null;
  isGameOver: boolean;
  logs: string[];
}

export interface GameState {
  gamePlayers: Player[];
  activePlayerIndex: number;
  auctionPlayerIndex: number | null;
  deck: Deck;
  rounds: Round[];
  auctionSelectedCardIds: number[];
  playerBids: Record<Player["name"], number>;
  bidSubmitTime: number | null;
  isGameOver: boolean;
  logs: string[];
  setGamePlayers: React.Dispatch<Player[]>;
  setActivePlayerIndex: React.Dispatch<number>;
  setAuctionPlayerIndex: React.Dispatch<number | null>;
  setDeck: React.Dispatch<Deck>;
  setRounds: React.Dispatch<Round[]>;
  nextPlayer: () => void;
  setAuctionSelectedCardIds: React.Dispatch<number[]>;
  submitPlayerBid: (player: Player, bidAmount: number) => void;
  setPlayerBids: React.Dispatch<Record<Player["name"], number>>;
  setBidSubmitTime: React.Dispatch<number | null>;
  roundShouldEnd: (auctionAddedCard: Card) => boolean;
  endRound: (unsoldPaintings: Card[]) => void;
  setGameOver: React.Dispatch<boolean>;
  addLog: (...args: any[]) => void;
  addPlayer: (name: string) => number;
  selectedCard: Card | null;
  setSelectedCard: React.Dispatch<React.SetStateAction<Card | null>>;
  auctionTimeLeftInMs: number | null;
  auctionInProgressType: AuctionType | null;
  isAuctionInProgress: boolean;
  activePlayer: Player;
  isConfirmationInProgress: boolean;
  setConfirmationInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PassAuctionPlayer(
  gamePlayers: Player[],
  auctionPlayerIndex: number | null,
  setAuctionPlayerIndex: React.Dispatch<number | null>
) {
  setAuctionPlayerIndex(
    incrementPlayerIndex(gamePlayers.length, auctionPlayerIndex ?? 0)
  );
}

interface GameStateProviderProps {
  gameId: string;
  children: React.ReactNode;
}

// @ts-ignore
const GameStateContext = createContext<GameState>({});

export default GameStateContext;

const GameStateProvider: React.FC<GameStateProviderProps> = ({
  children,
  gameId,
}) => {
  // All the mutable state below
  const [sharedState, setSharedState] = useState<SharedGameState>(() => {
    // Initial shared state, the pre-game lobby
    return {
      gamePlayers: [],
      activePlayerIndex: 0, // Kind of ugly default but will updated to proper value when starting game
      auctionPlayerIndex: null,
      bidSubmitTime: null,
      playerBids: {},
      deckCardIds: startDeck.map((c) => c.id),
      rounds: [],
      auctionSelectedCardIds: [],
      isGameOver: false,
      logs: [],
    };
  });

  const updateSharedState = useCallback(
    (getNewSharedState: (prevState: SharedGameState) => SharedGameState) => {
      setSharedState((prevState: SharedGameState) => {
        const newSharedState = getNewSharedState(prevState);
        sendSharedState(newSharedState, gameId);
        return newSharedState;
      });
    },
    [setSharedState, gameId]
  );

  useEffect(() => {
    const unsubscribe = onSharedStateChange(setSharedState, gameId);
    return unsubscribe;
  }, [setSharedState, gameId]);

  // Lots of boilerplate to break up the shared state into individual state variables
  const [gamePlayers, setGamePlayers] = useMemo(
    () => [
      sharedState.gamePlayers.map(sharedToPlayer),
      (gamePlayers: Player[]) =>
        updateSharedState((prevState) => ({
          ...prevState,
          gamePlayers: gamePlayers.map(playerToShared),
        })),
    ],
    [sharedState.gamePlayers, updateSharedState]
  );

  const [activePlayerIndex, setActivePlayerIndex] = useMemo(
    () => [
      sharedState.activePlayerIndex,
      (activePlayerIndex: number) =>
        updateSharedState((prevState) => ({ ...prevState, activePlayerIndex })),
    ],
    [sharedState.activePlayerIndex, updateSharedState]
  );

  const [auctionPlayerIndex, setAuctionPlayerIndex] = useMemo(
    () => [
      sharedState.auctionPlayerIndex,
      (auctionPlayerIndex: number | null) =>
        updateSharedState((prevState) => ({
          ...prevState,
          auctionPlayerIndex,
        })),
    ],
    [sharedState.auctionPlayerIndex, updateSharedState]
  );

  const [bidSubmitTime, setBidSubmitTime] = useMemo(
    () => [
      sharedState.bidSubmitTime,
      (bidSubmitTime: number | null) =>
        updateSharedState((prevState) => ({ ...prevState, bidSubmitTime })),
    ],
    [sharedState.bidSubmitTime, updateSharedState]
  );

  const [playerBids, setPlayerBids] = useMemo(
    () => [
      sharedState.playerBids,
      (playerBids: Record<Player["name"], number>) =>
        updateSharedState((prevState) => ({ ...prevState, playerBids })),
    ],
    [sharedState.playerBids, updateSharedState]
  );

  const [deck, setDeck] = useMemo(
    () => [
      sharedState.deckCardIds.map((cardId) => startDeck[cardId]),
      (deck: Deck) =>
        updateSharedState((prevState) => ({
          ...prevState,
          deckCardIds: deck.map((c) => c.id),
        })),
    ],
    [sharedState.deckCardIds, updateSharedState]
  );

  const [rounds, setRounds] = useMemo(
    () => [
      sharedState.rounds.map(sharedToRound),
      (rounds: Round[]) =>
        updateSharedState((prevState) => ({
          ...prevState,
          rounds: rounds.map(roundToShared),
        })),
    ],
    [sharedState.rounds, updateSharedState]
  );

  const [auctionSelectedCardIds, setAuctionSelectedCardIds] = useMemo(
    () => [
      sharedState.auctionSelectedCardIds,
      (auctionSelectedCardIds: number[]) =>
        updateSharedState((prevState) => ({
          ...prevState,
          auctionSelectedCardIds,
        })),
    ],
    [sharedState.auctionSelectedCardIds, updateSharedState]
  );

  const [isGameOver, setGameOver] = useMemo(
    () => [
      sharedState.isGameOver,
      (isGameOver: boolean) =>
        updateSharedState((prevState) => ({
          ...prevState,
          isGameOver,
        })),
    ],
    [sharedState.isGameOver, updateSharedState]
  );

  const [logs, addLog] = useMemo(
    () => [
      sharedState.logs,
      (...args: any[]) =>
        updateSharedState((prevState) => ({
          ...prevState,
          logs: [args.join(" "), ...prevState.logs],
        })),
    ],
    [sharedState.logs, updateSharedState]
  );
  // Boilerplate finally done

  // Local mutable state below. Not shared with other player clients.
  const [auctionTimeLeftInMs, setAuctionTimeLeftInMs] = useState<number | null>(
    null
  );
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [isConfirmationInProgress, setConfirmationInProgress] =
    useState<boolean>(false);
  // All the mutable state above

  // All the computed state below. These cannot be changed directly, but are calculated from the mutable state above.
  const activePlayer = useMemo(
    () => gamePlayers[activePlayerIndex],
    [activePlayerIndex, gamePlayers]
  );

  const isAuctionInProgress = useMemo(() => {
    return auctionSelectedCardIds.length > 0 ? true : false;
  }, [auctionSelectedCardIds]);

  const auctionInProgressType = useMemo(() => {
    if (!isAuctionInProgress) {
      return null;
    }

    const auctionSelectedCard =
      startDeck[auctionSelectedCardIds[auctionSelectedCardIds.length - 1]];
    const auctionType = auctionSelectedCard.auctionType;
    return auctionType;
  }, [auctionSelectedCardIds, isAuctionInProgress]);
  // All the computed state above

  // Various effects below
  // This useEffect tracks and controls the Open auction timer
  useEffect(() => {
    if (auctionInProgressType !== "open" || bidSubmitTime == null) {
      return;
    }
    const target = bidSubmitTime + OPEN_AUCTION_TIMEOUT;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const leftInMs = target - now;
      setAuctionTimeLeftInMs(leftInMs);

      if (leftInMs <= 0) {
        // Timer has reached 0 and auction hasn't ended yet
        endAuction(playerBids);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [auctionInProgressType, bidSubmitTime]);

  const afterAuctionBid = (
    currentPlayerBids: Record<Player["name"], number>
  ) => {
    if (!auctionInProgressType) {
      throw new Error("No auction in progress");
    }

    const shouldEndAuction = auctionLogics[auctionInProgressType].afterBid(
      gameState,
      currentPlayerBids,
      gameState.auctionPlayerIndex,
      setAuctionPlayerIndex
    );

    if (shouldEndAuction) {
      endAuction(currentPlayerBids);
    }
  };

  const submitPlayerBid = useCallback(
    (player: Player, bidAmount: number) => {
      const newPlayerBids = { ...playerBids };
      newPlayerBids[player.name] = bidAmount;
      setPlayerBids(newPlayerBids);
      afterAuctionBid(newPlayerBids);
    },
    [playerBids, setPlayerBids, afterAuctionBid]
  );

  const endAuction = (currentPlayerBids: Record<Player["name"], number>) => {
    if (!auctionInProgressType) {
      throw new Error("No auction in progress");
    }

    auctionLogics[auctionInProgressType].endAuction(
      gameState,
      currentPlayerBids
    );

    // This is where the auction related things get cleaned up.
    setAuctionSelectedCardIds([]);
    setBidSubmitTime(null);
    setPlayerBids({});
    setGamePlayers(gamePlayers);
    nextPlayer();
  };

  const addPlayer = useCallback(
    (name: string) => {
      const newGamePlayers = addPlayerLogic(gamePlayers, name);
      setGamePlayers(newGamePlayers);
      return newGamePlayers.length - 1;
    },
    [gamePlayers, setGamePlayers]
  );

  const nextPlayer = useCallback(() => {
    const nextPlayerIndex = incrementPlayerIndex(
      gamePlayers.length,
      activePlayerIndex
    );
    setActivePlayerIndex(nextPlayerIndex);
  }, [activePlayerIndex, gamePlayers.length, setActivePlayerIndex]);

  const roundShouldEnd = (auctionAddedCard: Card) => {
    let artistCount: Record<number, number> = Object.keys(Artists).reduce(
      (acc, id) => ({ ...acc, [id]: 0 }),
      {}
    );

    // Count all cards that have already been auction this round
    gamePlayers.forEach((player) => {
      // Iterate over each card in the player's paintingsBought array
      player.paintingsBought.forEach((card) => {
        // Increment the color count for the artist's color
        artistCount[card.artwork.artistId]++;
      });
    });

    // If there are any cards already in the auction (double auction), count those as well
    auctionSelectedCardIds.forEach((cardId) => {
      artistCount[startDeck[cardId].artwork.artistId]++;
    });

    // Also count the card we were about to add
    artistCount[auctionAddedCard.artwork.artistId]++;

    return Object.values(artistCount).some(
      (countPerArtist) => countPerArtist >= MAX_PAINTINGS_PER_ARTIST
    );
  };

  const endRound = (newUnsoldPaintings: Card[]) => {
    const currentRoundIndex = rounds.length - 1;

    if (currentRoundIndex < 0) {
      // Initializing game, should just be done automatically when we have a proper game starting setup
      const activePlayerIndex = gamePlayers.indexOf(
        determineStartingPlayer(gamePlayers)
      );
      const firstRound = {
        soldPaintings: [],
        unsoldPaintings: [],
        colorValues: initializeColorValues(),
      };
      setActivePlayerIndex(activePlayerIndex);
      distributeCards(gamePlayers, 1, deck);
      setGamePlayers(gamePlayers);
      setDeck(deck);
      setRounds([firstRound]);
      return;
    }

    const currentRound = rounds[currentRoundIndex];

    const auctionedPaintings = gamePlayers.reduce((auctioned, player) => {
      auctioned.push(...player.paintingsBought);
      return auctioned;
    }, [] as Card[]);

    currentRound.colorValues = calculateColorValues(
      auctionedPaintings,
      newUnsoldPaintings
    );

    const computedColorValues = initializeColorValues();

    for (const round of rounds) {
      const colorValues = round.colorValues;

      for (const color in computedColorValues) {
        if (colorValues[color as Color] === 0 && round === currentRound) {
          computedColorValues[color as Color] = 0;
        } else {
          computedColorValues[color as Color] += colorValues[color as Color];
        }
      }
    }

    //Log entry: color values
    const printColorValues = Object.entries(computedColorValues)
      .filter(([_, value]) => value > 0)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .map(
        ([color, value]) =>
          `${serializeColor(color)} is worth ${serializeMoney(value)}`
      )
      .join(", ");

    const unsoldPaintings = newUnsoldPaintings
      .map((card) => serializeCard(card))
      .join(", ");

    if (printColorValues.length > 0) {
      addLog(
        `End of round ${rounds.length}. ${printColorValues}. Unsold paintings: ${unsoldPaintings}`
      );
    }

    currentRound.soldPaintings = auctionedPaintings;
    currentRound.unsoldPaintings = newUnsoldPaintings;

    const nextGamePlayers = [...gamePlayers];
    // Log entry: selling paintings
    nextGamePlayers.forEach((player) => {
      if (player.paintingsBought.length > 0) {
        const soldPaintings = player.paintingsBought.map((card) => {
          return `${serializeCard(card)} (${serializeMoney(
            computedColorValues[Artists[card.artwork.artistId].color]
          )})`;
        });

        const paintingsList = soldPaintings.join(", ");

        addLog(serializePlayer(player, gamePlayers), "sold", paintingsList);
      }
    });

    // Sell the paintings from each player's hand, emptying their respective paintingsBought in the process
    sellPaintings(nextGamePlayers, computedColorValues);

    const nextRounds = [...rounds];

    if (nextRounds.length < MAX_ROUND_COUNT) {
      // End of game isn't reached, go to the next round
      nextRounds.push({
        soldPaintings: [],
        unsoldPaintings: [],
        colorValues: initializeColorValues(),
      });

      distributeCards(nextGamePlayers, nextRounds.length, deck);
      setDeck(deck);
      nextPlayer();
    } else {
      setGameOver(true);
    }

    // Cleanup anything necessary
    setAuctionSelectedCardIds([]);
    setPlayerBids({});
    setRounds(nextRounds);
    setGamePlayers(nextGamePlayers);
  };

  // Various effects above

  const gameState: GameState = useMemo(
    () => ({
      gamePlayers,
      activePlayer,
      activePlayerIndex,
      deck,
      rounds,
      selectedCard,
      playerBids,
      setGamePlayers,
      setActivePlayerIndex,
      setDeck,
      setRounds,
      setSelectedCard,
      nextPlayer,
      auctionSelectedCardIds,
      setAuctionSelectedCardIds,
      submitPlayerBid,
      auctionPlayerIndex,
      setAuctionPlayerIndex,
      setPlayerBids,
      isAuctionInProgress,
      bidSubmitTime,
      setBidSubmitTime,
      auctionTimeLeftInMs,
      auctionInProgressType,
      roundShouldEnd,
      endRound,
      isGameOver,
      setGameOver,
      logs,
      addLog,
      addPlayer,
      isConfirmationInProgress,
      setConfirmationInProgress,
    }),
    [
      gamePlayers,
      activePlayer,
      activePlayerIndex,
      deck,
      rounds,
      selectedCard,
      playerBids,
      setGamePlayers,
      setActivePlayerIndex,
      setDeck,
      setRounds,
      setSelectedCard,
      nextPlayer,
      auctionSelectedCardIds,
      setAuctionSelectedCardIds,
      submitPlayerBid,
      auctionPlayerIndex,
      setAuctionPlayerIndex,
      setPlayerBids,
      isAuctionInProgress,
      bidSubmitTime,
      setBidSubmitTime,
      auctionTimeLeftInMs,
      auctionInProgressType,
      roundShouldEnd,
      endRound,
      isGameOver,
      setGameOver,
      logs,
      addLog,
      addPlayer,
      isConfirmationInProgress,
      setConfirmationInProgress,
    ]
  );

  return (
    <GameStateContext.Provider value={gameState}>
      {children}
    </GameStateContext.Provider>
  );
};

// Export the provider component
export { GameStateProvider };
