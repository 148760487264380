import { AuctionLogic } from ".";
import React, { useContext } from "react";
import GameStateContext, { GameState } from "../../GameStateContext";
import { Player } from "../../data/Player";
import { buyPainting, incrementPlayerIndex } from "../GameActions";
import { Card, startDeck } from "../../data/cardData";
import { serializeCard, serializePlayer } from "../LogSerialization";
import ConfirmationModal from "../../components/ConfirmationModal";

function afterBid(
  _gameState: GameState,
  _newPlayerBids: Record<string, number>,
  _auctionPlayerIndex: number | null,
  _setAuctionPlayerIndex: React.Dispatch<number | null>
): boolean {
  throw new Error("Double auction should not recieve bids");
}

function endAuction(gameState: GameState) {
  // The only way to end a double auction is for all players to pass. The auctioneer gets the painting for free.
  const auctionSelectedCards = gameState.auctionSelectedCardIds.map(
    (id) => startDeck[id]
  );
  buyPainting(
    gameState.activePlayer,
    gameState.activePlayer,
    auctionSelectedCards,
    0
  );

  gameState.addLog(
    serializePlayer(gameState.activePlayer, gameState.gamePlayers),
    "wins",
    serializeCard(startDeck[auctionSelectedCards[0].id]),
    "for free!"
  );
  // cleanup
  gameState.setAuctionSelectedCardIds([]);
  gameState.setBidSubmitTime(null);
  gameState.setPlayerBids({});
  gameState.setGamePlayers(gameState.gamePlayers);
  gameState.nextPlayer();
}

interface RenderAuctionDialogContentProps {
  player: Player;
  playerIndex: number;
}

const RenderAuctionDialogContent: React.FC<RenderAuctionDialogContentProps> = ({
  player,
  playerIndex,
}) => {
  const gameState = useContext(GameStateContext);
  const { selectedCard } = gameState;

  const handleConfirm = () => {
    gameState.setConfirmationInProgress(false);
    if (!selectedCard) {
      throw new Error("No card selected when confirming");
      return;
    }
    player.hand = player.hand.filter((card) => card.id !== selectedCard.id);
    gameState.setGamePlayers(gameState.gamePlayers);
    gameState.setSelectedCard(null);

    if (gameState.roundShouldEnd(selectedCard)) {
      const firstCard: Card[] = gameState.auctionSelectedCardIds.map(
        (id) => startDeck[id]
      );
      gameState.endRound([...firstCard, selectedCard]);
      return;
    }

    if (selectedCard.auctionType === "oneoffer") {
      if (gameState.auctionPlayerIndex == null) {
        throw new Error(
          "No auction player index when adding second card in double auction"
        );
      }

      const nextAuctionPlayerIndex = incrementPlayerIndex(
        gameState.gamePlayers.length,
        gameState.auctionPlayerIndex,
        1
      );
      gameState.setAuctionPlayerIndex(nextAuctionPlayerIndex);
    }

    const newAuctionCardIds = [
      ...gameState.auctionSelectedCardIds,
      selectedCard.id,
    ];

    gameState.setActivePlayerIndex(playerIndex);
    gameState.setAuctionSelectedCardIds(newAuctionCardIds);
    gameState.addLog(
      "Second card added:",
      serializeCard(startDeck[newAuctionCardIds[1]])
    );
  };

  const handleCancel = () => {
    gameState.setConfirmationInProgress(false);
  };

  const addSecondCard = () => {
    if (!selectedCard) {
      throw new Error("No selected card when adding second card");
    }

    const shouldEndRound = gameState.roundShouldEnd(selectedCard);
    if (shouldEndRound) {
      gameState.setConfirmationInProgress(true);
      return;
    }

    player.hand = player.hand.filter((card) => card.id !== selectedCard.id);
    gameState.setGamePlayers(gameState.gamePlayers);
    gameState.setSelectedCard(null);

    if (gameState.roundShouldEnd(selectedCard)) {
      const firstCard: Card[] = gameState.auctionSelectedCardIds.map(
        (id) => startDeck[id]
      );
      gameState.endRound([...firstCard, selectedCard]);
      return;
    }

    if (selectedCard.auctionType === "oneoffer") {
      if (gameState.auctionPlayerIndex == null) {
        throw new Error(
          "No auction player index when adding second card in double auction"
        );
      }

      const nextAuctionPlayerIndex = incrementPlayerIndex(
        gameState.gamePlayers.length,
        gameState.auctionPlayerIndex,
        1
      );
      gameState.setAuctionPlayerIndex(nextAuctionPlayerIndex);
    }

    const newAuctionCardIds = [
      ...gameState.auctionSelectedCardIds,
      selectedCard.id,
    ];

    gameState.setActivePlayerIndex(playerIndex);
    gameState.setAuctionSelectedCardIds(newAuctionCardIds);
    gameState.addLog(
      "Second card added:",
      serializeCard(startDeck[newAuctionCardIds[1]])
    );
  };

  const handlePassPlayer = () => {
    const {
      gamePlayers,
      auctionPlayerIndex,
      activePlayerIndex,
      setAuctionPlayerIndex,
    } = gameState;

    // Calculate the next auction player index
    const nextAuctionPlayerIndex = incrementPlayerIndex(
      gamePlayers.length,
      auctionPlayerIndex ?? 0
    );

    // Check if the next auction player is the active player
    if (nextAuctionPlayerIndex === activePlayerIndex) {
      endAuction(gameState);
    }

    // Pass the turn to the next auction player
    setAuctionPlayerIndex(nextAuctionPlayerIndex);
  };

  return (
    <>
      {playerIndex === gameState.auctionPlayerIndex && (
        <>
          <b>DOUBLE AUCTION:</b>
          <div>
            Select another card of by the same artist that is not a double
            auction.
          </div>
          <button
            disabled={!selectedCard || gameState.isConfirmationInProgress}
            onClick={addSecondCard}
          >
            Add card
          </button>
          <button
            onClick={handlePassPlayer}
            disabled={
              gameState.isConfirmationInProgress || selectedCard !== null
            }
          >
            Pass
          </button>
          <p>&nbsp;</p>
          {gameState.isConfirmationInProgress && (
            <ConfirmationModal
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}
        </>
      )}
    </>
  );
};

const logic: AuctionLogic = {
  afterBid,
  endAuction,
  RenderAuctionDialogContent,
};

export default logic;
