import { Player } from "../data/Player";
import { Artist, Artists, Card, Color, startDeck } from "../data/cardData";

// SERIALIZE

export function serializePlayer(player: Player, gamePlayers: Player[]): string {
  const playerIndex = gamePlayers.findIndex((p) => p.name === player.name);
  return `%${playerIndex}%`;
}

export function serializeCard(card: Card): string {
  return `[${card.id}]`;
}

export function serializeCards(cards: Card[]): string {
  if (cards.length === 1) {
    return serializeCard(cards[0]);
  }

  return `${serializeCard(cards[0])} and ${serializeCard(cards[1])}`;
}

export function serializeMoney(amount: number): string {
  return `$${amount}$`;
}

export function serializeColor(color: string): string {
  switch (color) {
    case "yellow":
      return "+0+";
    case "blue":
      return "+1+";
    case "red":
      return "+2+";
    case "green":
      return "+3+";
    case "orange":
      return "+4+";
    default:
      return color;
  }
}

// UNSERIALIZE

export function unserializePlayer(
  serializedPlayer: string,
  gamePlayers: Player[]
): Player | null {
  const playerIndex = parseInt(serializedPlayer.slice(1, -1));
  return gamePlayers[playerIndex] || null;
}

export function unserializeCard(serializedCard: string): Card | null {
  const cardId = parseInt(serializedCard.slice(1, -1));
  const card = startDeck[cardId];
  return card || null;
}
export function unserializeMoney(serializedMoney: string): number | null {
  if (serializedMoney.startsWith("$") && serializedMoney.endsWith("$")) {
    const amount = parseFloat(serializedMoney.slice(1, -1));
    return isNaN(amount) ? null : amount;
  }
  return null;
}

export function unserializeColor(artistId: string): Artist | undefined {
  const id = Number(artistId);
  return Artists[id];
}
