import React, { ReactNode } from "react";
import { AuctionType } from "../data/cardData";

export const auctionIcons: Record<AuctionType, ReactNode> = {
  oneoffer: (
    <svg viewBox="0 1 24 24" fill="black" stroke="none">
      <path d="M12 1.52l2.876 8.836h9.248l-7.541 5.489 2.876 8.836-7.54-5.489-7.54 5.489 2.876-8.836-7.54-5.489h9.248l2.876-8.836z" />
      <text
        x="48%"
        y="65%"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="10"
        fontWeight="800"
        fill="white"
      >
        1
      </text>
    </svg>
  ),
  double: (
    <svg viewBox="0 0 125.92 122.88">
      <g>
        <path d="M97.48,26.61L97.48,26.61c3.09,3.09,3.15,8.08,0.14,11.1l-1.63,1.63l13.1,12.88L75.96,84.14L63.2,70.92 l-49.74,49.74c-3.01,3.01-8.01,2.95-11.1-0.14l0,0c-3.09-3.09-3.15-8.08-0.14-11.1l49.92-49.92l-9.99-10.39l31.32-31.93l11.21,11 l1.72-1.72C89.4,23.46,94.39,23.52,97.48,26.61L97.48,26.61z M72.11,1.88L72.11,1.88c2.46,2.46,2.51,6.43,0.11,8.83L35.69,47.24 c-2.4,2.4-6.37,2.35-8.83-0.11l0,0c-2.46-2.46-2.51-6.43-0.11-8.83L63.28,1.77C65.68-0.63,69.65-0.58,72.11,1.88L72.11,1.88z M124.04,53.81L124.04,53.81c2.46,2.46,2.51,6.43,0.11,8.83L87.62,99.18c-2.4,2.4-6.37,2.35-8.83-0.11l0,0 c-2.46-2.46-2.51-6.43-0.11-8.83l36.53-36.53C117.61,51.3,121.58,51.35,124.04,53.81L124.04,53.81z" />
      </g>
    </svg>
  ),
  fixedprice: (
    <svg viewBox="0 0 32 32">
      <defs>
        <clipPath id="clip-pricetag">
          <rect width="32" height="32" />
        </clipPath>
      </defs>
      <g id="pricetag" clipPath="url(#clip-pricetag)">
        <g
          id="Group_2408"
          data-name="Group 2408"
          transform="translate(-156 -260)"
        >
          <g id="Group_2404" data-name="Group 2404">
            <g id="Group_2403" data-name="Group 2403">
              <g id="Group_2402" data-name="Group 2402">
                <path
                  id="Path_3858"
                  data-name="Path 3858"
                  d="M174.716,280.041a2.81,2.81,0,0,0-.9-.555q-.5-.192-1.354-.426v-2.853a1.553,1.553,0,0,1,1.09,1.139.855.855,0,0,0,.871.727.827.827,0,0,0,.607-.251.81.81,0,0,0,.251-.593,1.66,1.66,0,0,0-.168-.679,2.9,2.9,0,0,0-.477-.72,2.483,2.483,0,0,0-.916-.686,4.277,4.277,0,0,0-1.258-.3V274.1c0-.375-.147-.562-.439-.562s-.433.192-.433.576v.727a3.36,3.36,0,0,0-2.17.878,2.94,2.94,0,0,0-.367,3.416,2.582,2.582,0,0,0,1,.888,8.413,8.413,0,0,0,1.536.566v3.189a1.837,1.837,0,0,1-.737-.377,1.67,1.67,0,0,1-.415-.545,8.714,8.714,0,0,1-.322-.861.741.741,0,0,0-.295-.4.91.91,0,0,0-.522-.144.859.859,0,0,0-.641.264.832.832,0,0,0-.257.594,2.364,2.364,0,0,0,.192.909,3,3,0,0,0,.593.891,3.327,3.327,0,0,0,1,.717,4.317,4.317,0,0,0,1.4.377v1.852a.867.867,0,0,0,.1.467.384.384,0,0,0,.346.151.335.335,0,0,0,.347-.186,1.626,1.626,0,0,0,.079-.59v-1.708a3.839,3.839,0,0,0,1.694-.514,2.906,2.906,0,0,0,1.08-1.115,3.04,3.04,0,0,0,.367-1.464,2.838,2.838,0,0,0-.23-1.149A2.563,2.563,0,0,0,174.716,280.041Zm-3.128-1.234a2.777,2.777,0,0,1-.919-.505,1.058,1.058,0,0,1-.3-.819,1.069,1.069,0,0,1,.322-.851,2.41,2.41,0,0,1,.9-.453Zm1.942,4.485a1.884,1.884,0,0,1-1.07.556v-3a3,3,0,0,1,1.08.552,1.168,1.168,0,0,1,.367.922A1.41,1.41,0,0,1,173.53,283.292Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
          <g id="Group_2407" data-name="Group 2407">
            <g id="Group_2406" data-name="Group 2406">
              <g id="Group_2405" data-name="Group 2405">
                <path
                  id="Path_3859"
                  data-name="Path 3859"
                  d="M181.273,271.04l-4.151-7.189a1,1,0,0,0-.866-.5H172.5v-2.7a.5.5,0,0,0-1,0v2.7h-3.756a1,1,0,0,0-.866.5l-4.149,7.187a1,1,0,0,0-.271.684v19.129a1,1,0,0,0,1,1h17.082a1,1,0,0,0,1-1V271.723A1,1,0,0,0,181.273,271.04Zm-1.731,18.811H164.458V272.043l3.863-6.692H171.5v1.822a2,2,0,1,0,1,0v-1.821h3.178l3.864,6.691ZM172,269.6a.5.5,0,0,0,.5-.5v-.846a.988.988,0,0,1,.5.846,1,1,0,1,1-1.707-.706,1.018,1.018,0,0,1,.207-.14v.846A.5.5,0,0,0,172,269.6Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  open: (
    <svg viewBox="0 0 122.88 83.78">
      <g>
        <path d="M95.73,10.81c10.53,7.09,19.6,17.37,26.48,29.86l0.67,1.22l-0.67,1.21c-6.88,12.49-15.96,22.77-26.48,29.86 C85.46,79.88,73.8,83.78,61.44,83.78c-12.36,0-24.02-3.9-34.28-10.81C16.62,65.87,7.55,55.59,0.67,43.1L0,41.89l0.67-1.22 c6.88-12.49,15.95-22.77,26.48-29.86C37.42,3.9,49.08,0,61.44,0C73.8,0,85.45,3.9,95.73,10.81L95.73,10.81z M60.79,22.17l4.08,0.39 c-1.45,2.18-2.31,4.82-2.31,7.67c0,7.48,5.86,13.54,13.1,13.54c2.32,0,4.5-0.62,6.39-1.72c0.03,0.47,0.05,0.94,0.05,1.42 c0,11.77-9.54,21.31-21.31,21.31c-11.77,0-21.31-9.54-21.31-21.31C39.48,31.71,49.02,22.17,60.79,22.17L60.79,22.17L60.79,22.17z M109,41.89c-5.5-9.66-12.61-17.6-20.79-23.11c-8.05-5.42-17.15-8.48-26.77-8.48c-9.61,0-18.71,3.06-26.76,8.48 c-8.18,5.51-15.29,13.45-20.8,23.11c5.5,9.66,12.62,17.6,20.8,23.1c8.05,5.42,17.15,8.48,26.76,8.48c9.62,0,18.71-3.06,26.77-8.48 C96.39,59.49,103.5,51.55,109,41.89L109,41.89z" />
      </g>
    </svg>
  ),
  hidden: (
    <svg viewBox="-22.5 -20 136 162">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.892,56.036h8.959v-1.075V37.117c0-10.205,4.177-19.484,10.898-26.207v-0.009 C29.473,4.177,38.754,0,48.966,0C59.17,0,68.449,4.177,75.173,10.901l0.01,0.009c6.721,6.723,10.898,16.002,10.898,26.207v17.844 v1.075h7.136c1.59,0,2.892,1.302,2.892,2.891v61.062c0,1.589-1.302,2.891-2.892,2.891H2.892c-1.59,0-2.892-1.302-2.892-2.891 V58.927C0,57.338,1.302,56.036,2.892,56.036L2.892,56.036z M26.271,56.036h45.387v-1.075V36.911c0-6.24-2.554-11.917-6.662-16.03 l-0.005,0.004c-4.111-4.114-9.787-6.669-16.025-6.669c-6.241,0-11.917,2.554-16.033,6.665c-4.109,4.113-6.662,9.79-6.662,16.03 v18.051V56.036L26.271,56.036z M49.149,89.448l4.581,21.139l-12.557,0.053l3.685-21.423c-3.431-1.1-5.918-4.315-5.918-8.111 c0-4.701,3.81-8.511,8.513-8.511c4.698,0,8.511,3.81,8.511,8.511C55.964,85.226,53.036,88.663,49.149,89.448L49.149,89.448z"
        />
      </g>
    </svg>
  ),
};
