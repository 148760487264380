import { useContext, useEffect, useState } from "react";
import GameStateContext from "../GameStateContext";
import CardImage from "./CardImage";
import { Artists, Card, startDeck } from "../data/cardData";

export default function PlayerHand({ playerIndex }: { playerIndex: number }) {
  const {
    gamePlayers,
    activePlayerIndex,
    auctionPlayerIndex,
    auctionSelectedCardIds,
    isAuctionInProgress,
    auctionInProgressType,
    isGameOver,
    selectedCard,
    setSelectedCard,
    isConfirmationInProgress,
  } = useContext(GameStateContext);

  const player = gamePlayers[playerIndex];

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Logic to determine if selection is disabled; especially for double type
  let isSelectingDisabled = isAuctionInProgress || isConfirmationInProgress;
  if (isAuctionInProgress) {
    if (isConfirmationInProgress) {
      isSelectingDisabled = true;
    } else if (
      auctionInProgressType === "double" &&
      auctionSelectedCardIds.length < 2
    ) {
      isSelectingDisabled = false;
    }
  }

  const handleCardClick = (
    playerIndex: number,
    cardIndex: number,
    card: Card
  ) => {
    let selectingPlayer = gamePlayers[activePlayerIndex];

    // Check if an auction is in progress or the game is over
    if (isSelectingDisabled || isGameOver) {
      return; // Do nothing if an auction is in progress
    }

    if (auctionInProgressType === "double") {
      if (auctionPlayerIndex === null) {
        throw Error(
          "Double auction in progress without auctionPlayerIndex set"
        );
      }

      selectingPlayer = gamePlayers[auctionPlayerIndex];

      const doubleCard = startDeck[auctionSelectedCardIds[0]];
      if (
        card.auctionType === "double" ||
        Artists[card.artwork.artistId].color !==
          Artists[doubleCard.artwork.artistId].color
      )
        return;
    } else if (playerIndex !== activePlayerIndex) {
      // In all other cases than an active double auction, you can only select if you're the activePlayer
      return;
    }

    // Toggle the selected state of the clicked card
    const clickedCard = selectingPlayer.hand[cardIndex];
    if (clickedCard.id === selectedCard?.id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(clickedCard);
    }
  };

  const mid = Math.floor(player.hand.length / 2);
  let translateScale = 1.3;
  if (windowSize.width < 1024) {
    translateScale = 0.6;
  }

  const shouldCardsMove = playerIndex !== activePlayerIndex;
  //(isAuctionInProgress && auctionInProgressType != "double");

  return (
    <>
      <div
        className="card-grid"
        style={{
          transform: shouldCardsMove ? "translateY(20%)" : "",
        }}
      >
        {player.hand.map((card, cardIndex) => {
          const cardWidth = `${windowSize.width / 2 / player.hand.length}px`;

          return (
            <div
              key={card.id}
              onClick={() => handleCardClick(playerIndex, cardIndex, card)}
              className={`card ${
                isSelectingDisabled || isGameOver ? "disabled" : ""
              }`}
              style={{ width: cardWidth }}
            >
              <CardImage
                style={{
                  zIndex: cardIndex,
                  transform: `rotate(${cardIndex - mid}deg) translateY(${
                    translateScale * Math.pow(cardIndex - mid, 2)
                  }px)`,
                }}
                card={card}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
